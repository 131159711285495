import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact";

import { MDBBtn } from "mdbreact";
import moment from "moment";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import { DatePicker, Spin } from "antd";
import axios from "axios";
import { baseUrl } from "../../Store/Apis/BaseUrl";

// Components
import BaseComponent from "../BaseComponent";
import DatatTableComponent from "../Partials/_DatatableComponet";

// Actions
import * as caseActions from "../../Store/Actions/caseActions";

import { dateFormat, checkDate } from "../../Helpers/dateHelper";
const { RangePicker } = DatePicker;

function mapStateToProps(store) {
  return {
    cases: store.cases,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    caseActions: bindActionCreators(caseActions, dispatch),
  };
}

let sitesPaymentsColumns = [
  {
    label: "Site",
    field: "site",
    sort: "asc",
    width: 150,
  },
  {
    label: "Total Payments To Date",
    field: "totalPayment",
    sort: "asc",
    width: 150,
  },
  {
    label: "Total Patients Scanned",
    field: "totalPatientsScanned",
    sort: "asc",
    width: 150,
  },
  {
    label: "Total Payable Patients Scanned",
    field: "totalPayablePatients",
    sort: "asc",
    width: 150,
  },
  {
    label: "Total Patients Payed",
    field: "totalPatientsPaid",
    sort: "asc",
    width: 150,
  },
  {
    label: "Patients Pending Payments",
    field: "totalPatientsPendingPayment",
    sort: "asc",
    width: 150,
  },
];

let paymentsColumns = [
  {
    label: "Site",
    field: "site",
    width: 150,
  },
  {
    label: "Date",
    field: "paymentDate",
    width: 150,
  },
  {
    label: "# of Patients",
    field: "NoOfPatients",
    width: 150,
  },
  {
    label: "Amount of Payment",
    field: "amount",
    width: 150,
  },
  {
    label: "Actions",
    field: "actions",
    width: 150,
  },
];
class CombinedCasesComponent extends React.Component {
  state = {
    caseIdBeingUpdated: null,
    loading: false,
    editedRowsValues: {}, // {PDG23123:{paid:true,comments:"das"} }
    payments: [],
    sitesPayments: [],
  };

  componentDidMount() {
    // this.props.caseActions.getCombinedCases();
    const fetchPaymentsData = async () => {
      const res = await axios.get(`${baseUrl}/payments`);
      const { success, payments } = res.data;
      if (success) {
        let formatted_payments_rows = payments.map((payment, i) => {
          return {
            site: payment.site_info[0].title,
            paymentDate: dateFormat(payment.paymentDate),
            NoOfPatients: payment.NoOfPatients,
            amount: "$" + payment.amount,
            actions: "edit ",
            id: payment._id,
            // actions: () => {
            //   <Link>Edit </Link>;
            // },
          };
        });

        this.setState({
          loading: false,
          payments: formatted_payments_rows,
        });
        console.log("payments:", payments);
        // this.prepareCasesDataToFitDynamicTable(data);
      } else {
        console.log("Error Getting Payments ");
      }
    };
    const fetchSitesPaymentsData = async () => {
      const res = await axios.get(`${baseUrl}/payments/sites`);
      const { success, sites } = res.data;
      if (success) {
        let totalPayment = 0;
        let totalPatientsScanned = 0;
        let totalPayablePatients = 0;
        let totalPatientsPaid = 0;
        let totalPatientsPendingPayment = 0;
        let formatted_payments_rows = sites.map((payment, i) => {
          totalPayment += parseInt(payment.totalPayment);
          totalPatientsScanned += payment.totalPatientsScanned;
          totalPayablePatients += payment.totalPayablePatients;
          totalPatientsPaid += payment.totalPatientsPaid;
          totalPatientsPendingPayment += payment.totalPatientsPendingPayment;
          return {
            site: payment.site,
            totalPayment: "$" + payment.totalPayment,
            totalPatientsScanned: payment.totalPatientsScanned,
            totalPayablePatients: payment.totalPayablePatients,
            totalPatientsPaid: payment.totalPatientsPaid,
            totalPatientsPendingPayment: payment.totalPatientsPendingPayment,
          };
        });

        formatted_payments_rows.push({
          site: "Total",
          totalPayment: "$" + totalPayment,
          totalPatientsScanned: totalPatientsScanned,
          totalPayablePatients: totalPayablePatients,
          totalPatientsPaid: totalPatientsPaid,
          totalPatientsPendingPayment: totalPatientsPendingPayment,
        });

        this.setState({
          loading: false,
          sitesPayments: formatted_payments_rows,
        });
        console.log("sites:", sites);
        // this.prepareCasesDataToFitDynamicTable(data);
      } else {
        console.log("Error Getting Payments ");
      }
    };
    fetchSitesPaymentsData();
    fetchPaymentsData();
  }

  exportToExcel = async () => {
    const preparedCases = this.state.cases.map((combinedCase) => {
      const prepareObject = {};
      sitesPaymentsColumns.forEach((col) => {
        let field = col.field;
        if (combinedCase[field]) {
          prepareObject[field] = combinedCase[field];
          if (field === "liver_diseases") {
            prepareObject[field] = combinedCase[field] + "";
          }
          if (field === "history") {
            prepareObject[field] = combinedCase[field] + "";
          }

          if (field === "history_other") {
            prepareObject[field] = combinedCase[field] + "";
          }
        } else {
          prepareObject[field] = "";
        }
      });
      return prepareObject;
    });
    var ws = XLSX.utils.json_to_sheet(preparedCases, {
      header: sitesPaymentsColumns.map((r) => r.field),
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    await XLSX.writeFile(wb, "Combined Cases.xls");
  };

  render() {
    let rows = [];
    let { payments, sitesPayments, loading } = this.state;
    let newPaymentsRowsWithBtn = payments.map((payment) => {
      // payments.actions = "a7a";
      return {
        ...payment,
        actions: <Link to={`edit-payment/${payment.id}`}> Edit</Link>,
        id: null,
      };
    });
    console.log("newPaymentsRowsWithBtn", newPaymentsRowsWithBtn);
    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h3 className="panel-title"> Payments</h3>
            <div className="row">
              <div className="col-md-12">
                <div className="payments-header">
                  {/* <button
                    className="btn btn-success export-btn"
                    onClick={this.exportToExcel}
                  >
                    Export to Excel
                  </button> */}
                  <Link to="add-new-payment">
                    <p>+ Add New Payment</p>
                  </Link>
                </div>
              </div>
            </div>
          </header>
          <div className="row">
            <div className="col-md-12 filter-tab">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="table-title"> Totals</h3>
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    ref={(el) => (this.country = el)}
                    placeholder="Filter by country"
                    className="form-control "
                  />
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary clear"
                    onClick={this.filter}
                  >
                    Filter
                  </button>
                  <button className="btn btn-danger " onClick={this.clear}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12 payments-table last-row-bold">
              {loading ? (
                <Spin />
              ) : (
                <MDBDataTable
                  // scrollX
                  // scrollY
                  // maxHeight="400px"
                  striped
                  bordered
                  hover
                  sorting={false}
                  searching={false}
                  sorting={false}
                  data={{ columns: sitesPaymentsColumns, rows: sitesPayments }}
                  entries={5}
                />
              )}
            </div>
          </div>
          <div className="row">
            <hr></hr>
          </div>
          <div className="row">
            <div className="col-md-12 filter-tab">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="table-title"> Payments</h3>
                </div>
              </div>
            </div>
            <div className="col-md-12 payments-table">
              {this.state.loading ? (
                <Spin />
              ) : (
                <MDBDataTable
                  // scrollX
                  // scrollY
                  // maxHeight="400px"
                  striped
                  bordered
                  hover
                  sorting={false}
                  searching={false}
                  sorting={false}
                  data={{
                    columns: paymentsColumns,
                    rows: newPaymentsRowsWithBtn,
                  }}
                  entries={10}
                />
              )}
            </div>
          </div>
        </BaseComponent>
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CombinedCasesComponent);

// export default CombinedCasesComponent;
