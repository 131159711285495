import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Progress } from "antd";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import store2 from "store2";
import { baseUrl } from "../../../Store/Apis/BaseUrl";
import { DatePicker, Spin } from "antd";
import Select from "react-select";
import Pdf from "react-to-pdf";

// Components
import FooterComponent from "../../../Components/Partials/_FooterComponent";
import HeaderComponent from "../../../Components/Partials/_HeaderComponent";
import SidebarComponent from "../../../Components/Partials/_SidebarComponent";
import HomepageLoader from "../../../Components/Loaders/_HomepageLoader";
import ReportsComponent from "../../../Components/Reports";

// Actions
// import * as deviceActions from "../../Store/Actions/deviceActions";
// import * as caseActions from "../../Store/Actions/caseActions";

const filterOptions = [
  { value: "all", label: "View All" },
  { value: "pending", label: "View Pending Manual Review" },
  { value: "reviewd", label: "View Reviewed" },
];

function mapStateToProps(store) {
  return {
    devicesStore: store.devices,
    casesStore: store.cases,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // deviceActions: bindActionCreators(deviceActions, dispatch),
    // caseActions: bindActionCreators(caseActions, dispatch),
  };
}

/**
 * 1- diseases
 * 2- usabale
 * 3- unusable
 */

const USER = store2.get("user");
const JWT_TOKEN = store2.get("token");
const reportToPDFRef = React.createRef();

class HomepageComponent extends React.Component {
  state = {
    selectedCountry: "all",
    dataBar: {},
    stats: {},
    loading: true,
    comments_count: {},
    allPatientsCount: 0,
    usable: 0,
    unusable: 0,
    pending: 0,
    paid: 0,
    dataQualityChart: [],
    paidUsableCasesChart: [],
    diseasesGraph: [],
    devices: [{ value: "all", label: "View All" }],
  };
  componentDidMount() {
    // this.props.deviceActions.getDevices();
    // this.props.caseActions.getCases();
    this.setState({ selectedCountry: USER.country });
    this.getReports();
    this.getSiteUsers();
  }
  getReports = async () => {
    let url = `${baseUrl}/cases/stats?site=${USER.siteId}`;
    if (this.startDate && this.endDate) {
      url += `&startDate=${this.startDate}&endDate=${this.endDate}`;
    }
    const res = await axios.get(url);

    const { success, data } = res.data;

    if (success) {
      let dataBar = {};
      let labels = [];
      let bar_data = [];
      data.yearlyGraph.map((g) => {
        labels.push(g._id);
        bar_data.push(g.count);
      });
      dataBar.labels = labels;
      dataBar.datasets = [
        {
          label: "Patients Scaned Per Month",
          data: bar_data,
          // backgroundColor: DataBarbackgroundColor,
          backgroundColor: "rgba(21,71,106)",
        },
      ];
      console.log("dataBar:", dataBar);
      let totalDataQualityChart = [
        {
          id: "usable",
          label: "usable",
          value: data.totalUsable,
        },
        {
          id: "unusable",
          label: "unusable",
          value: data.totalUnusable,
        },
        {
          id: "pending",
          label: "Pending",
          value: data.totalPending,
        },
      ];
      let dataQualityChart = [
        {
          id: "usable",
          label: "usable",
          value: data.ussable,
        },
        {
          id: "unusable",
          label: "unusable",
          value: data.unusable,
        },
        {
          id: "pending",
          label: "Pending",
          value: data.uending,
        },
      ];
      let paidUsableCasesChart = [
        {
          id: "usable",
          label: "usable",
          value: data.totalUsable - data.totalPaid,
        },
        {
          id: "paid",
          label: "paid",
          value: data.totalUsable,
        },
      ];
      this.setState({
        loading: false,
        stats: data,
        comments_count: data.comments_count,
        totalPatients: data.totalPatients,
        totalUsable: data.totalUsable,
        totalUnusable: data.totalUnusable,
        totalPending: data.totalPending,
        totalPaid: data.totalPaid,
        totalDataQualityChart,

        patients: data.patients,
        usable: data.usable,
        unusable: data.unusable,
        pending: data.pending,
        paid: data.paid,
        dataBar,
        dataQualityChart,
        paidUsableCasesChart,
        diseasesGraph: data.diseasesGraph,
      });
      console.log("Cases stats:", data);
    } else {
    }
  };

  getDeviceNameFromEmail = (email) => {
    let name = email.substring(0, email.lastIndexOf("@oncoustics.com"));
    return name;
  };

  getSiteUsers = () => {
    axios
      .get(`${baseUrl}/sites/${USER.siteId}`, {
        headers: {
          token: JWT_TOKEN,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          let devices = [{ value: "all", label: "View All" }];
          res.data.devices.forEach((device) => {
            devices.push({
              value: device.userId,
              label: this.getDeviceNameFromEmail(device.email),
            });
          });

          this.setState({
            devices,
          });
        } else {
        }
      })
      .catch((err) => {
        return err;
      });
  };

  filterByDevice = ({ value }) => {
    let doctorId = value;
    console.log(doctorId);
    if (doctorId === "all") {
      this.getReports();
    } else {
      this.getReports(doctorId);
    }
  };

  render() {
    if (this.state.loading) {
      return <HomepageLoader />;
    }

    if (this.state.loading) {
      return <HomepageLoader />;
    }

    let {
      selectedSite,
      selectedCountry,
      totalPatients,
      totalUsable,
      totalUnusable,
      totalPending,
      totalPaid,
      totalDataQualityChart,

      patients,
      usable,
      unusable,
      pending,
      paid,
      dataQualityChart,

      // paidUsableCasesChart,
      diseasesGraph,
      comments_count,
      dataBar,
      paidCasesCount,
      devices,
    } = this.state;

    let paidUsableCasesChart = [
      {
        id: "usable",
        label: "usable",
        value: totalUsable - paidCasesCount,
      },
      {
        id: "paid",
        label: "paid",
        value: paidCasesCount,
      },
    ];

    console.log(
      totalPatients,
      totalUsable,
      totalUnusable,
      totalPending,
      totalPaid,

      usable,
      unusable,
      pending,
      paid,
      patients
    );
    return (
      <>
        <HeaderComponent />
        <SidebarComponent />
        <div className="page">
          <div className="page-content container-fluid">
            <div className="dashboard-content">
              <div
                className="dashboard-header"
                style={{ display: "inline-flex" }}
              >
                {/* <h4>China: Clarius 01,Clarius 02,Clarius 03</h4> */}
                <Pdf
                  targetRef={reportToPDFRef}
                  filename="oncoustics-report.pdf"
                >
                  {({ toPdf }) => (
                    <button
                      className="btn btn-success btn-export"
                      onClick={toPdf}
                    >
                      Export PDF
                    </button>
                  )}
                </Pdf>
                <Select
                  options={devices}
                  onChange={this.filterByDevice}
                  classNamePrefix="customSelect"
                  placeholder="View All"
                />
              </div>
              <div ref={reportToPDFRef}>
                {/* <ReportsComponent
                  selectedCountry={selectedCountry}
                  allPatientsCount={allPatientsCount}
                  usable={usable}
                  unusable={unusable}
                  pending={pending}
                  paid={paid}
                  dataQualityChart={dataQualityChart}
                  paidUsableCasesChart={paidUsableCasesChart}
                  diseasesGraph={diseasesGraph}
                  comments_count={comments_count}
                  dataBar={dataBar}
                > */}
                <ReportsComponent
                  selectedCountry={selectedCountry}
                  totalPatients={totalPatients}
                  totalUsable={totalUsable}
                  totalUnusable={totalUnusable}
                  totalPending={totalPending}
                  totalPaid={totalPaid}
                  totalDataQualityChart={totalDataQualityChart}
                  patients={patients}
                  usable={usable}
                  unusable={unusable}
                  pending={pending}
                  paid={paid}
                  dataQualityChart={dataQualityChart}
                  paidUsableCasesChart={paidUsableCasesChart}
                  diseasesGraph={diseasesGraph}
                  comments_count={comments_count}
                  dataBar={dataBar}
                >
                  <div class="col-xl-12 col-md-12">
                    <h1 className="dashboard-site-title">{selectedCountry}</h1>
                    {/* <h2 className="stats-time-seprator">Last Week: Sept 14-18</h2> */}
                  </div>
                </ReportsComponent>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomepageComponent);
