import React from "react";
import ContentLoader from "react-content-loader";
import BaseComponent from "../BaseComponent";

const HomepageLoader = () => (
	<BaseComponent>
		<ContentLoader
			height={160}
			width={400}
			speed={2}
			primaryColor="#f3f3f3"
			secondaryColor="#ecebeb"
		>
			<rect x="14" y="12" rx="0" ry="0" width="107" height="64" />
			<rect x="292" y="60" rx="0" ry="0" width="0" height="0" />
			<rect x="159" y="14" rx="0" ry="0" width="135" height="59" />
		</ContentLoader>
	</BaseComponent>
);

export default HomepageLoader;
