import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
import { Spin } from "antd";
// Components
import BaseComponent from "../BaseComponent";
import DatatTableComponent from "../Partials/_DatatableComponet";
import { dateFormat } from "../../Helpers/dateHelper";

// Actions
import * as clariusScrapperActions from "../../Store/Actions/clariusScrapperActions";

function mapStateToProps(store) {
  return {
    clariusScrapper: store.clariusScrapper
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clariusScrapperActions: bindActionCreators(clariusScrapperActions, dispatch)
  };
}

const datatableComponent = [
  {
    label: "Case Id",
    field: "caseId",
    sort: "asc",
    width: 150
  },
  {
    label: "Device",
    field: "device",
    sort: "asc",
    width: 150
  },
  {
    label: "clinician",
    field: "clinician",
    sort: "asc",
    width: 150
  },
  {
    label: "Images",
    field: "images",
    sort: "asc",
    width: 150
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 150
  },
  {
    label: "exam Date",
    field: "examDate",
    sort: "asc",
    width: 150
  },
  {
    label: "Upload Date",
    field: "uploadDate",
    sort: "asc",
    width: 150
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 150
  }
];

const DatatableActions = props => {
  let { caseLink } = props;
  let link = "";
  if (caseLink) {
    link = caseLink
      .split("= '")
      .pop()
      .split("';")[0];
  }

  return (
    <a href={`https://cloud.clarius.com${link}`}>
      <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
    </a>
  );
};

class ListComponent extends React.Component {
  state = {
    syncing: false
  };
  componentDidMount() {
    this.props.clariusScrapperActions.getCases();
  }
  sync = () => {
    this.props.clariusScrapperActions.sync();
    this.setState({
      syncing: true
    });
    setTimeout(() => {
      this.setState({
        syncing: false
      });
    }, 60000);
  };
  render() {
    const rows = this.props.clariusScrapper.cases.map(clariusCase => {
      return {
        caseId: clariusCase.caseId,
        device: clariusCase.device,
        clinician: clariusCase.clinician,
        images: clariusCase.files,
        status: clariusCase.clariusStatus,
        examDate: clariusCase.examDate,
        uploadDate: clariusCase.uploadDate,
        actions: <DatatableActions caseLink={clariusCase.caseLink} />
      };
    });
    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h3 className="panel-title"> Clarius Cases</h3>
            <div class="page-header-actions">
              {this.state.syncing ? (
                <Spin size="small">
                  <button
                    class="btn btn-sm btn-primary btn-round waves-effect"
                    href="#"
                    onClick={this.sync}
                  >
                    <i class="icon md-link" aria-hidden="true"></i>
                    <span class="hidden-sm-down">Sync</span>
                  </button>
                </Spin>
              ) : (
                <button
                  class="btn btn-sm btn-primary btn-round waves-effect"
                  onClick={this.sync}
                >
                  <i class="icon md-link" aria-hidden="true"></i>
                  <span class="hidden-sm-down">Sync</span>
                </button>
              )}
            </div>
          </header>
          <div className="panel-body">
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <div class="m-b-15">
                  <p>
                    Last Sync Date{" "}
                    {dateFormat(
                      this.props.clariusScrapper.lastSyncDate.createdAt
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <DatatTableComponent columns={datatableComponent} rows={rows} />
        </BaseComponent>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListComponent);

// export default ListComponent;
