import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";

import Fuse from "fuse.js";
import XLSX from "xlsx";
import { DatePicker, Spin } from "antd";
import axios from "axios";
import { baseUrl } from "../../Store/Apis/BaseUrl";

// Components
import BaseComponent from "../BaseComponent";
import TableComponent from "./TableComponent";
// Actions
import * as caseActions from "../../Store/Actions/caseActions";

import { dateFormat, checkDate } from "../../Helpers/dateHelper";
const { RangePicker } = DatePicker;

function mapStateToProps(store) {
  return {
    cases: store.cases,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    caseActions: bindActionCreators(caseActions, dispatch),
  };
}

let newDatatableColumns = [
  {
    label: "Case Id",
    field: "caseId",
    sort: "asc",
    width: 150,
  },
  {
    label: "Country",
    field: "country",
    sort: "asc",
    width: 150,
  },
  {
    label: "liver diseases",
    field: "liver_diseases",
    sort: "asc",
    width: 150,
  },
  {
    label: "existance",
    field: "existance",
    sort: "asc",
    width: 150,
  },
  {
    label: "date",
    field: "date",
    sort: "asc",
    width: 150,
  },
];

const columnsToHide = [
  "_id",
  "patientInfo",
  "caseLink",
  "createdAt",
  "__v",
  "deviceId",
  "caseSourceType",
  "caseFiles",
  "filePath",
  "fileSize",
  "filename",
  "isUploaded",
  "tarFilesYmlInfo",
  "comments",
  "paid",
  "doctorId",
  "filesCount",
  "reportImages",
  "reportImagesUrls",
  "updatedAt",
  "createdAt",
  "bucketVideosCount",
  "bucketRfCount",
  "exams",
  "id",
  "exam_files",
  "exam_notes",
  "caseId",
  "case_id",
  "file_source_type",
  "updated_at",
  "bucket_videos_count",
  "bucket_rf_count",
  "manual_usable",
  "report_is_pdf",
  "report_comments",
  "doctor_id",
  "report_images",
  "report_images_urls",
  "reportimages",
  "qualitycheck",
  "images",
  "created_at",
  "updated_at",
  "dashboard_notes",
  "dashboard_comments",

  // Some Diseases Combiner
  "none_fibroscan_cap", // => PDG1625012456535
  "fatty_fibroscan_cap", // => PDG1575423185338
  "cirrhosis_fibroscan_cap", // => PDG1623895905532
  "steatosis_fibroscan_cap", // => PDG1621807355863
  "fibrosis_fibroscan_cap", // => PDG1621620335603

  "none_fibroscan_kpa", // =>PDG1621966354037
  "fatty_fibroscan_kpa", // => PDG1598422733838
  "cirrhosis_fibroscan_kpa", // =>PDG1597990183663
  "steatosis_fibroscan_kpa", // => PDG1626228465180
  "fibrosis_fibroscan_kpa", // => PDG1626228093320

  "none_biopsy_value", // => PDG1622142132741
  "hcc_biopsy", // => PDG1622142003054
  "nash_biopsy_value", // => PDG1621620335603
  "ballooning_biopsy_value", // => PDG1621618766955
  "steatosis_biopsy_value", // => PDG1621619294858
  "fibrosis_biopsy_value", // => PDG1621610545933
  "cirrhosis_biopsy_value", // =>
];

const columnsToShow = [
  "caseId",
  "email",
  "site_title",
  "country",
  // "doctorId",
  "history",
  "liver_diseases",
  "biopsy_value",
  "fibroscan_kpa",
  "fibroscan_cap",
  "date",
  "age",
  "gender",
  "height",
  "Weight",
  "bmi",
  // "usable",
  "manualeUsable",
  "Automatic_Usable",
  "Data_Quality_Internal",
  "manualeUsable",
  "usable",
  "history_other",
  "presence_ascites",
  "portal_vein_thrombosis",
  // "status",
  // "lesion_size",
  // "number_of_lesions",
  // "location_of_segment",
  // "stage_note",
  // "shear_wave_elastography",
  // "liver_disease_extra_notes",
  "liver_disease_fibrosis_grade",
  "exams_notes",
  "exams_files",
  // "listener_counter",
  // "isYMLProcessed",
  "clariusVideos",
  "clariusRfs",
];
const filterOptions = [
  { value: "all", label: "View All" },
  { value: "pending", label: "View Pending Manual Review" },
  { value: "reviewd", label: "View Reviewed" },
];

class QualityChecksComponent extends React.Component {
  state = {
    syncing: false,
    cases: [],
    filteredCases: [],
    pagintaedCases: [],
    caseIdBeingUpdated: null,
    loading: true,
    editedRowsValues: {}, // {PDG23123:{paid:true,comments:"das"} }
    page: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  };
  age;
  country;
  firstDate;
  secondDate;

  componentDidMount() {
    this.getBackendCases();
  }

  getBackendCases = async () => {
    const res = await axios.get(`${baseUrl}/cases/combined-new`);
    const { success, data } = res.data;
    if (success) {
      this.setState({
        loading: false,
        totalDocs: data.length,
        totalPages: data.length / this.state.limit,
      });
      this.prepareCasesDataToFitDynamicTable(data);
    } else {
      console.log("Error Getting new Combined cases");
    }
  };

  paginate = (page, results) => {
    let filteredCases = [];
    if (results) {
      filteredCases = results;
    } else {
      filteredCases = this.state.filteredCases;
    }
    let pagintaedCases = filteredCases.slice(
      (page - 1) * this.state.limit,
      page * this.state.limit
    );

    console.log("New page cases:", pagintaedCases.length);
    this.setState({
      page,
      pagintaedCases,
      totalDocs: filteredCases.length,
    });
  };

  updateLimit = (limit) => {
    console.log("updateLimit", limit.value);
    let filteredCases = this.state.filteredCases;
    this.setState({ loading: true });
    if (limit.value === "all") {
      this.setState({
        limit: limit.value,
        filteredCases: filteredCases,
        loading: false,
      });
    } else {
      let pagintaedCases = filteredCases.slice(
        (this.state.page - 1) * limit.value,
        this.state.page * limit.value
      );
      this.setState({
        limit: limit.value,
        pagintaedCases,
        loading: false,
      });
    }
  };

  prepareCasesDataToFitDynamicTable = (cases) => {
    let combined_cases = cases;
    // to prevent duplicates as it cause a weird issue !!
    let fieldNamesArray = [
      "caseId",
      "country",
      "liver_diseases",
      "existance",
      "date",
    ];
    let singleCase;
    let field;

    for (singleCase of combined_cases) {
      singleCase["fibroscan_cap"] =
        singleCase.none_fibroscan_cap ||
        singleCase.fatty_fibroscan_cap ||
        singleCase.cirrhosis_fibroscan_cap ||
        singleCase.steatosis_fibroscan_cap ||
        singleCase.fibrosis_fibroscan_cap ||
        "";

      singleCase["fibroscan_kpa"] =
        singleCase.none_fibroscan_kpa ||
        singleCase.fatty_fibroscan_kpa ||
        singleCase.cirrhosis_fibroscan_kpa ||
        singleCase.steatosis_fibroscan_kpa ||
        singleCase.fibrosis_fibroscan_kpa ||
        "";

      singleCase["biopsy_value"] =
        singleCase.none_biopsy_value ||
        singleCase.hcc_biopsy ||
        singleCase.nash_biopsy_value ||
        singleCase.ballooning_biopsy_value ||
        singleCase.steatosis_biopsy_value ||
        singleCase.fibrosis_biopsy_value ||
        singleCase.cirrhosis_biopsy_value ||
        "";

      singleCase["Automatic_Usable"] = singleCase.usable
        ? singleCase.usable.toString()
        : "-";
      singleCase["Data_Quality_Internal"] = singleCase.manualeUsable
        ? singleCase.manualeUsable.toString()
        : "-";

      for (field in singleCase) {
        // to hide non-used columns
        // if (
        //   !columnsToHide.includes(field) &&
        //   !fieldNamesArray.includes(field)
        // ) {
        //   newDatatableColumns.push({
        //     label: field.replace("_", " "),
        //     field: field,
        //     sort: "asc",
        //     width: 150,
        //   });
        //   fieldNamesArray.push(field);
        // }

        if (columnsToShow.includes(field)) {
          newDatatableColumns.push({
            label: field.replace("_", " "),
            field: field,
            sort: "asc",
            width: 150,
          });
          fieldNamesArray.push(field);
        }
      }
    }
    newDatatableColumns = newDatatableColumns.filter((col, index, self) => {
      return index === self.findIndex((t) => t.field === col.field);
    });
    newDatatableColumns.push({
      label: "Notes",
      field: "notes",
      sort: "asc",
      width: 150,
    });
    newDatatableColumns.push({
      label: "Comments",
      field: "comments",
      sort: "asc",
      width: 150,
    });
    newDatatableColumns.push({
      label: "Manual Usable",
      field: "manualeUsable",
      sort: "asc",
      width: 150,
    });
    newDatatableColumns.push({
      label: "Automatic Usable",
      field: "usable",
      sort: "asc",
      width: 150,
    });

    newDatatableColumns.push({
      label: "Edit",
      field: "edit",
      sort: "asc",
      width: 150,
    });
    newDatatableColumns.push({
      label: "Actions",
      field: "actions",
      sort: "asc",
      width: 150,
    });
    console.log("fields::", newDatatableColumns);

    this.setState({ cases: combined_cases, filteredCases: combined_cases });
    this.paginate(1);
  };

  exportToExcel = async () => {
    const preparedCases = this.state.cases.map((combinedCase) => {
      const prepareObject = {};
      newDatatableColumns.forEach((col) => {
        let field = col.field;
        if (combinedCase[field]) {
          prepareObject[field] = combinedCase[field];
          if (field === "liver_diseases") {
            prepareObject[field] = combinedCase[field] + "";
          }
          if (field === "history") {
            prepareObject[field] = combinedCase[field] + "";
          }

          if (field === "history_other") {
            prepareObject[field] = combinedCase[field] + "";
          }
        } else {
          prepareObject[field] = "";
        }
      });
      return prepareObject;
    });
    var ws = XLSX.utils.json_to_sheet(preparedCases, {
      header: newDatatableColumns.map((r) => r.field),
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    await XLSX.writeFile(wb, "Combined Cases.xls");
  };

  search = (input) => {
    const pattern = input.target.value;
    let { cases } = this.state;
    if (pattern.length == 0) {
      this.paginate(1, cases);
      return;
    }

    let keys = newDatatableColumns.map((c) => c.field);
    // console.log(keys);
    // return;
    const options = {
      isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: true,
      // findAllMatches: true,
      minMatchCharLength: 3,
      // location: 0,
      threshold: 0.1,
      distance: 5,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // keys: cases.map((c) => Object.keys(c)),
      // keys: newDatatableColumns.map((c) => c.field),
      // keys: keys,
      keys: [
        "caseId",
        "country",
        "liver_disease",
        "gender",
        "fibrosis_grade",
        "status",
        "fibrosis_grade",
      ],
    };

    const fuse = new Fuse(cases, options);
    let results = fuse.search(pattern);
    results = results.map((r) => r.item);
    console.log("resulyts", pattern, keys, results);

    // this.setState({ filteredCases: results });
    // this.setState({ pagintaedCases: results });
    this.paginate(1, results);
  };

  filterByDate = (dates) => {
    if (dates.length === 0) {
      this.firstDate = null;
      this.secondDate = null;
      return;
    }
    this.firstDate = dates[0];
    this.secondDate = dates[1];
  };

  render() {
    let { pagintaedCases } = this.state;

    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h3 className="panel-title"> Quality Checks</h3>
            <div className="row">
              <div className="col-md-10">
                <button
                  className="btn btn-success export-btn"
                  onClick={this.exportToExcel}
                >
                  Export to Excel
                </button>
                {/* <RangePicker allowClear onChange={this.filterByDate} /> */}
              </div>
              <div className="col-md-12 filter-tab">
                <div className="row">
                  <div className="col-md-3">
                    <div style={{ display: "flex" }}>
                      <label className="sort-by-label">View </label>
                      <Select
                        options={filterOptions}
                        // onChange={}
                        classNamePrefix="customSelect"
                        placeholder="View All"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      onChange={this.search}
                      placeholder="Search"
                      className="form-control "
                    />
                  </div>
                </div>
              </div>
            </div>
          </header>
          {this.state.loading ? (
            <Spin />
          ) : (
            <>
              <TableComponent
                newDatatableColumns={newDatatableColumns}
                cases={pagintaedCases}
                paginate={this.paginate}
                updateLimit={this.updateLimit}
                page={this.state.page}
                limit={this.state.limit}
                // totalPages={totalPages}
                totalDocs={this.state.totalDocs}
              />
            </>
          )}
        </BaseComponent>
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualityChecksComponent);

// export default QualityChecksComponent;
