import React from "react";
import { Link } from "react-router-dom";
import store2 from "store2";
import axios from "axios";
import { baseUrl } from "../../Store/Apis/BaseUrl";
// Components
import ErrorComponent from "../Partials/_ErrorComponent";

class LoginComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
      loginCode: null,
      showSuccessMsg: false,
      errMsg: "",
      form: {
        email: "",
        password: "",
      },
    };
  }

  componentDidMount() {
    document.body.className = "animsition page-login-v2 layout-full page-dark";
    document.title = "Oncoustics | Login";
  }

  handleInputChange = (event) => {
    const form = { ...this.state.form };
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  login = (e) => {
    e.preventDefault();
    const { email, password } = this.state.form;
    axios
      .post(`${baseUrl}/auth/forget_password`, { email, password })
      .then((response) => {
        let { success, message, data } = response.data;
        console.log("Login Code", success);

        if (success) {
          this.setState({
            hasError: false,
            showSuccessMsg: true,
          });
        } else {
          this.setState({
            hasError: true,
            errMsg: [message],
          });
        }
      })
      .catch((err) => {
        this.setState({
          hasError: true,
        });
      });
  };

  enterLoignCode = (e) => {
    e.preventDefault();
    const { loginCode } = this.state.form;
    store2.set("user", this.state.user);
    if (loginCode === this.state.loginCode) {
      this.setTokenAndRedirect();
    } else {
      this.setState({
        hasError: true,
        errMsg: ["Login Code Incorrect"],
      });
    }
  };

  setTokenAndRedirect = () => {
    store2.set("token", this.state.token);
    store2.set("type", this.state.type);
    store2.set("user", this.state.user);

    if (this.state.type === "site") {
      this.props.history.push("/site");
      window.location.reload();
    } else {
      this.props.history.push("/");
      window.location.reload();
    }
  };

  render() {
    return (
      <div className="page">
        <div className="page-content">
          <div className="page-brand-info"></div>
          <div className="page-login-main">
            <div className="auth-header ">
              <img className="auth-logo" src="/logo_full.png" alt="..." />
              {/* <h3 className="brand-text font-size-40">Oncoustics</h3> */}
            </div>
            <h3 className="auth-text"> Reset password</h3>
            <p>Reset password</p>
            {this.state.hasError && (
              <ErrorComponent errors={this.state.errMsg} />
            )}

            {!this.state.showSuccessMsg && (
              <>
                <form onSubmit={this.login}>
                  <div
                    className="form-group form-material floating auth-form-field"
                    data-plugin="formMaterial"
                  >
                    <input
                      type="email"
                      className="form-control empty"
                      id="inputEmail"
                      name="email"
                      onChange={this.handleInputChange}
                    />
                    <label className="floating-label" htmlFor="inputEmail">
                      Email
                    </label>
                  </div>
                  <div
                    className="form-group form-material floating auth-form-field"
                    data-plugin="formMaterial"
                  ></div>
                  <button type="submit" className="btn  btn-onc btn-login">
                    Reset Password
                  </button>
                </form>
                <p className="auth-helper">
                  Dont have an account?
                  <Link to="/register"> Register</Link>
                </p>
              </>
            )}
            {this.state.showSuccessMsg && (
              <p className="auth-helper">
                Email Sent, Login
                <Link to="/login"> Login</Link>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LoginComponent;
