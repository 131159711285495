// AWS Links
// export let assetsUrl = "http://3.92.100.82:4000";
// export let baseUrl = "http://3.92.100.82:4000/api";
// if (process.env.REACT_APP_API === "stg") {
//   baseUrl = "http://54.172.254.196:4000/api";
//   assetsUrl = "http://54.172.254.196:4000";
// }

//=========Google Links ============
export let assetsUrl = "https://pd.api.oncoustics.com";
export let baseUrl = "https://pd.api.oncoustics.com/api";
if (process.env.REACT_APP_API === "local") {
  assetsUrl = "http://localhost:4000/api";
  baseUrl = "http://localhost:4000";
}

/**
 * TEST CASES WITH NO DISEASSES
 * 
 * PDG1646967124246
PDG1646964159794
PDG1646795127234
PDG1646187002009
PDG1645757303405
PDG1645756721305
PDG1645755270174
PDG1645207664338
PDG1645201886630
PDG1645151980735
PDG1645151073091
PDG1645150608879
PDG1645149754933
PDG1644980147962
PDG1644978003077
PDG1644975825470
PDG1644817901909
PDG1644607413721
PDG1644546825956
PDG1644546264987
PDG1644509643769
PDG1644373769207
PDG1644373250110
PDG1644016228901
PDG1643338327207
PDG1643163783513
PDG1643162108349
PDG1642511213094
PDG1644016228901
PDG1642125712451
PDG1642124983523
PDG1641523038853
6:37
These ones are missing the columns:
6:37
PDG1645757303405
PDG1645756721305
PDG1645755270174
PDG1645207664338
PDG1645201886630
PDG1645151980735
PDG1645151073091
PDG1645150608879
PDG1645149754933
PDG1644980147962
PDG1644978003077
PDG1644975825470
PDG1644817901909
PDG1644607413721
PDG1644546825956
PDG1644546264987
PDG1644509643769
PDG1644373769207
PDG1644373250110
PDG1644016228901
PDG1643338327207
PDG1643163783513
PDG1643162108349
PDG1642511213094
PDG1644016228901
PDG1642125712451
PDG1642124983523
PDG1641523038853





isAutomaticUsable
isYMLProcessed
doctorId 
siteId

{"patientInfo.steatosis_grade":{$exists:true}}


 */
