import * as actionTypes from "./actionTypes";
import caseApi from "../Apis/caseApi";

/**************************** Devices ******************************/
export function getCases() {
  return function(dispatch) {
    dispatch(handle_fetching_cases());
    caseApi
      .cases()
      .then(res => {
        if (res.success === true) {
          dispatch(handle_getting_cases_success(res.data));
        } else {
          dispatch(handle_getting_cases_error(res.data));
        }
      })
      .catch(err => {});
  };
}

function handle_fetching_cases() {
  return {
    type: actionTypes.FETCHING_CASES
  };
}

function handle_getting_cases_success(cases) {
  return {
    type: actionTypes.GET_CASES_SUCCESS,
    cases
  };
}

function handle_getting_cases_error(err) {
  return {
    type: actionTypes.GET_CASES_ERROR,
    err
  };
}

/**************************** Single Device ******************************/
export function getCase(caseId) {
  return function(dispatch) {
    dispatch(handle_fetching_case());
    caseApi
      .singleCase(caseId)
      .then(res => {
        if (res.success === true) {
          dispatch(handle_getting_case_success(res.data));
        } else {
          dispatch(handle_getting_case_error(res.data));
        }
      })
      .catch(err => {});
  };
}

function handle_fetching_case() {
  return {
    type: actionTypes.FETCHING_CASES
  };
}

function handle_getting_case_success(caseData) {
  return {
    type: actionTypes.GET_CASE_SUCCESS,
    case: caseData
  };
}

function handle_getting_case_error(err) {
  return {
    type: actionTypes.GET_CASE_ERROR,
    err: err || "Server Error"
  };
}

/**************************** Devices ******************************/
export function getCombinedCases() {
  return function(dispatch) {
    dispatch(handle_fetching_combined_cases());
    caseApi
      .combinedCases()
      .then(res => {
        if (res.success === true) {
          dispatch(handle_getting_combined_cases_success(res.data));
        } else {
          dispatch(handle_getting_combined_cases_error(res.data));
        }
      })
      .catch(err => {});
  };
}

function handle_fetching_combined_cases() {
  return {
    type: actionTypes.FETCHING_COMBINED_CASES
  };
}

function handle_getting_combined_cases_success(cases) {
  return {
    type: actionTypes.GET_COMBINED_CASES_SUCCESS,
    cases
  };
}

function handle_getting_combined_cases_error(err) {
  return {
    type: actionTypes.GET_COMBINED_CASES_ERROR,
    err
  };
}
