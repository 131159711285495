import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Pagination from "react-js-pagination";
import BasicTableRow from "./TableRow";

const sortOptions = [
  { value: "10", label: "10 per page" },
  { value: "50", label: "50 per page" },
  { value: "100", label: " 100 per page" },
];

const TableComponent = (props) => {
  let loading = false;
  let status = "completed";
  let {
    newDatatableColumns,
    cases,
    updateLimit,
    paginate,
    page,
    totalDocs,
    limit,
  } = props;
  console.log("Table Is Rendered");
  return (
    <>
      <div className="cases-table">
        <div className="table-overflow">
          <table className={`${loading ? "hide-this" : ""}`}>
            {/* <tr id="t01"> */}
            {/* <th class="plusheader">+</th> */}
            <thead className="table-header">
              {newDatatableColumns.map((col, index) => {
                return (
                  <th key={index}>
                    {col.label} <i class="fas fa-sort"></i>
                  </th>
                );
              })}
            </thead>
            <tbody>
              {/* </tr> */}
              {cases.map((singleCase, key) => {
                return (
                  <>
                    <tr>
                      <BasicTableRow
                        newDatatableColumns={newDatatableColumns}
                        singleCase={singleCase}
                      />
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row table-footer">
        <div className="col-md-1" />
        <div className="col-md-5">
          <div
            style={{ display: "flex" }}
            className={`${loading ? "hide-this" : ""}`}
          >
            <label className="sort-by-label">View </label>
            <Select
              options={sortOptions}
              onChange={updateLimit}
              classNamePrefix="customSelect"
              placeholder="Per Page"
            />
          </div>
        </div>

        <div className="col-md-5">
          <Pagination
            prevPageText="prev"
            nextPageText="next"
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalDocs}
            pageRangeDisplayed={5}
            onChange={paginate}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(TableComponent);
