import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
// Components
import BaseComponent from "../BaseComponent";
import DatatTableComponent from "../Partials/_DatatableComponet";

// Actions
import * as caseActions from "../../Store/Actions/caseActions";

function mapStateToProps(store) {
  return {
    casesStore: store.cases
  };
}

function mapDispatchToProps(dispatch) {
  return {
    caseActions: bindActionCreators(caseActions, dispatch)
  };
}

const datatableComponent = [
  {
    label: "Case Id",
    field: "caseId",
    sort: "asc",
    width: 150
  },

  {
    label: "Device Id",
    field: "deviceId",
    sort: "asc",
    width: 150
  },

  {
    label: "Country",
    field: "country",
    sort: "asc",
    width: 150
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 150
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 150
  }
];

const DatatableActions = props => (
  <Link to={`#`}>
    <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
  </Link>
);

class ListComponent extends React.Component {
  componentDidMount() {
    this.props.caseActions.getCases();
  }
  render() {
    const rows = this.props.casesStore.cases.map(device => {
      return {
        caseId: device.caseId,
        deviceId: device.deviceId,
        country: device.country,
        status: device.status,
        actions: <DatatableActions deviceId={device.deviceId} />
      };
    });
    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h3 className="panel-title"> Cases</h3>
          </header>
          <DatatTableComponent columns={datatableComponent} rows={rows} />
        </BaseComponent>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListComponent);

// export default ListComponent;
