import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { MDBBtn } from "mdbreact"
import { Link } from "react-router-dom"
import axios from "axios"
import { baseUrl } from "../../../Store/Apis/BaseUrl"

// Components
import BaseComponent from "../../../Components/BaseComponent"
import DatatTableComponent from "../../../Components/Partials/_DatatableComponet"

// Actions
// import * as deviceActions from "../../Store/Actions/deviceActions";

// function mapStateToProps(store) {
//   return {
//     devicesStore: store.devices,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     deviceActions: bindActionCreators(deviceActions, dispatch),
//   };
// }

const datatableColumns = [
  {
    label: "First Name",
    field: "firstName",
    sort: "asc",
    width: 150,
  },
  {
    label: "last Name",
    field: "lastName",
    sort: "asc",
    width: 150,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 150,
  },
  {
    label: "Country",
    field: "country",
    sort: "asc",
    width: 150,
  },
  {
    label: "specialization",
    field: "specialization",
    sort: "asc",
    width: 150,
  },
  {
    label: "institution",
    field: "institution",
    sort: "asc",
    width: 150,
  },
  //   {
  //     label: "Actions",
  //     field: "actions",
  //     sort: "asc",
  //     width: 150,
  //   },
]

const DatatableActions = (props) => (
  <>
    {/* <Link to={`/devices/${props.deviceId}`}>
      <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
    </Link>{" "}
    <Link to={`/devices/${props.deviceId}/cases`}>
      <MDBBtn color="purple" size="sm">{`Cases `}</MDBBtn>
    </Link> */}
  </>
)

class ListComponent extends React.Component {
  state = {
    accounts: [],
  }
  componentDidMount() {
    // this.props.deviceActions.getDevices();
    this.fetchAccounts()
  }

  fetchAccounts = async () => {
    const res = await axios.get(`${baseUrl}/accounts`)
    const { success, accounts } = res.data
    this.setState({
      accounts: [],
    })
    if (success) {
      this.setState({
        accounts,
      })
    }
  }
  render() {
    const rows = this.state.accounts.map((account) => {
      return {
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        country: account.country,
        specialization: account.specialization,
        institution: account.institution,

        // actions: <DatatableActions deviceId={account.deviceId} />,
      }
    })
    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h3 className="panel-title"> Accounts</h3>
            <div className="row">
              <div className="col-md-10">
                <Link to="/accounts/create">
                  <button className="btn btn-success export-btn">
                    Add Account
                  </button>
                </Link>
              </div>
            </div>
          </header>
          <DatatTableComponent columns={datatableColumns} rows={rows} />
        </BaseComponent>
      </>
    )
  }
}

export default ListComponent
// export default connect(mapStateToProps, mapDispatchToProps)(ListComponent);

// export default ListComponent;
