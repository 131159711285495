import React from "react";
import { DatePicker, Space, Select, Input } from "antd";
import NumericInput from "./NumericInput";

const { Option } = Select;
const PaymentRow = (props) => {
  let {
    row,
    index,
    sites,
    changeSite,
    changeDate,
    changeNoOfPatients,
    changeAmount,
    deleteRow,
    paymentsRows,
  } = props;

  return (
    <tr>
      <td>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select Site"
          optionFilterProp="children"
          onChange={(value) => changeSite(index, value)}
        >
          {sites.map((site, index) => {
            return <Option value={site._id}>{site.title}</Option>;
          })}
        </Select>
      </td>
      <td>
        <DatePicker onChange={(value) => changeDate(index, value)} />
      </td>
      <td>
        <NumericInput
          style={{ width: 120 }}
          // defaultValue={row.NoOfPatients}
          value={row.NoOfPatients}
          onChange={(value) => changeNoOfPatients(index, value)}
        />
      </td>
      <td>
        <NumericInput
          style={{ width: 120 }}
          // defaultValue={row.amount}
          value={row.amount}
          onChange={(value) => changeAmount(index, value)}
        />
      </td>
      {/* <td>
        <button
          style={{
            width: 120,
          }}
          disabled={paymentsRows.length === 1 ? true : false}
          className="btn btn-red"
          onClick={() => deleteRow(index)}
        >
          Delete
        </button>
      </td> */}
    </tr>
  );
};

export default PaymentRow;
