import * as actionTypes from "./actionTypes";
import deviceApi from "../Apis/deviceApi";

/**************************** Devices ******************************/
export function getDevices() {
  return function(dispatch) {
    dispatch(handle_fetching_devices());
    deviceApi
      .devices()
      .then((res) => {
        if (res.success === true) {
          dispatch(handle_getting_devices_success(res.data));
        } else {
          dispatch(handle_getting_devices_error(res.data));
        }
      })
      .catch((err) => {});
  };
}

function handle_fetching_devices() {
  return {
    type: actionTypes.FETCHING_DEVICES,
  };
}

function handle_getting_devices_success(devices) {
  return {
    type: actionTypes.GET_DEVICES_SUCCESS,
    devices,
  };
}

function handle_getting_devices_error(err) {
  return {
    type: actionTypes.GET_DEVICES_ERROR,
    err,
  };
}

/**************************** Single Device ******************************/
export function getDevice(deviceId) {
  return function(dispatch) {
    dispatch(handle_fetching_device());
    deviceApi
      .device(deviceId)
      .then((res) => {
        if (res.success === true) {
          dispatch(handle_getting_device_success(res.data));
        } else {
          dispatch(handle_getting_device_error(res.data));
        }
      })
      .catch((err) => {});
  };
}

function handle_fetching_device() {
  return {
    type: actionTypes.FETCHING_DEVICE,
  };
}

function handle_getting_device_success(device) {
  return {
    type: actionTypes.GET_DEVICE_SUCCESS,
    device,
  };
}

function handle_getting_device_error(err) {
  return {
    type: actionTypes.GET_DEVICE_ERROR,
    err: err || "Server Error",
  };
}

/**************************** Single Device Latest Cases ******************************/
export function getDeviceLatestCasesInfo(deviceId) {
  return function(dispatch) {
    dispatch(handle_fetching_device_latest_cases_info());
    deviceApi
      .deviceLatestCasesInfo(deviceId)
      .then((res) => {
        if (res.success === true) {
          dispatch(handle_getting_device_latest_cases_success(res.data));
        } else {
          dispatch(handle_getting_device_latest_cases_error(res.data));
        }
      })
      .catch((err) => {});
  };
}

function handle_fetching_device_latest_cases_info() {
  return {
    type: actionTypes.FETCHING_DEVICE_LATEST_CASES_INFO,
  };
}

function handle_getting_device_latest_cases_success(latestCases) {
  return {
    type: actionTypes.GET_DEVICE_LATEST_CASES_INFO_SUCCESS,
    latestCases,
  };
}

function handle_getting_device_latest_cases_error(err) {
  return {
    type: actionTypes.GET_DEVICE_LATEST_CASES_INFO_ERROR,
    err: err || "Server Error",
  };
}

/**************************** Single Device Cases ******************************/
export function getDeviceCases(deviceId) {
  return function(dispatch) {
    dispatch(handle_fetching_device_cases());
    deviceApi
      .deviceCases(deviceId)
      .then((res) => {
        if (res.success === true) {
          dispatch(handle_getting_device_cases_success(res.data));
        } else {
          dispatch(handle_getting_device_cases_error(res.data));
        }
      })
      .catch((err) => {});
  };
}

function handle_fetching_device_cases() {
  return {
    type: actionTypes.FETCHING_DEVICE_CASES,
  };
}

function handle_getting_device_cases_success(latestCases) {
  return {
    type: actionTypes.GET_DEVICE_CASES_SUCCESS,
    latestCases,
  };
}

function handle_getting_device_cases_error(err) {
  return {
    type: actionTypes.GET_DEVICE_CASES_ERROR,
    err: err || "Server Error",
  };
}

/**************************** Notifications ******************************/
export function sendNotification(
  firebaseToken,
  notificationAction,
  notificationCaseId = null
) {
  return function(dispatch) {
    dispatch(handle_sending_notificatio());
    deviceApi
      .sendNotification(firebaseToken, notificationAction, notificationCaseId)
      .then((res) => {
        if (res.success === true) {
          dispatch(handle_sending_notification_success(res.data));
        } else {
          dispatch(handle_sending_notification_error(res.data));
        }
      })
      .catch((err) => {});
  };
}

function handle_sending_notificatio() {
  return {
    type: actionTypes.SENDING_NOTIFICATION,
  };
}

function handle_sending_notification_success(device) {
  return {
    type: actionTypes.SEND_NOTIFICATION_SUCCESS,
    device,
  };
}

function handle_sending_notification_error(err) {
  return {
    type: actionTypes.SEND_NOTIFICATION_ERROR,
    err,
  };
}
