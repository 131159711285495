import React, { useState, useMemo, useCallback, useEffect } from "react";
import { MDBBtn } from "mdbreact";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { baseUrl } from "../../Store/Apis/BaseUrl";

const commentsOptions = [
  { value: "incorrect_depth", label: "Incorrect Depth" },
  { value: "excess_shadowing", label: "Excess Shadowing" },
  { value: "poor_liver_visibility", label: "Poor Liver Visibility" },
  { value: "fast_sweeps", label: "Fast Sweeps" },
  { value: "missing_liver_view_labels", label: "Missing Liver View Labels" },
  {
    value: "missing_relevant_clinical_information",
    label: "Missing Relevant Clinical Information",
  },
  { value: "other", label: "Other" },
];

const dataQualityOptions = [
  { value: "usable", label: "Usable" },
  { value: "unusable", label: "Unusable" },
  { value: "undetermined", label: "undetermined" },
];

/**
 * Row fields Component
 * @param {*} props
 */
const BasicTableRow = (props) => {
  const [_rowType, setRowType] = useState("info");
  const [_notes, setNotes] = useState("");
  const [_comments, setComments] = useState("");
  const [_manualeUsable, setUsability] = useState("");

  let { newDatatableColumns, singleCase } = props;

  const handleCommentsChange = (input) => {
    setComments(input.value);
  };
  const handleNotesChange = (input) => {
    setNotes(input.target.value);
  };
  const handleDataQualityChange = (input) => {
    setUsability(input.value);
  };

  const changeRowtype = (type) => {
    setRowType(type);
  };

  /**
   * update Csse Info and save into  backend
   */
  const saveCaseInfo = async () => {
    // update into database
    let caseId = singleCase.caseId;
    let rowValues = {
      comments: _comments,
      manualeUsable: _manualeUsable,
      notes: _notes,
    };
    const res = await axios.post(
      `${baseUrl}/cases/${singleCase.caseId}/info`,
      rowValues
    );
    const { success, data } = res.data;
    if (success) {
      console.log(" Saved Row In Backend :", caseId, rowValues);
      singleCase.dashboard_comments = data.dashboard_comments;
      singleCase.dashboard_notes = data.dashboard_notes;
      singleCase.manualeUsable = data.manualeUsable;

      setRowType("info");
    } else {
      console.log("Error saving row");
    }

    setRowType("info");
  };

  singleCase.actions = (
    <DatatableActions
      caseLink={singleCase.caseLink}
      caseId={singleCase.caseId}
    />
  );

  if (_rowType === "info") {
    singleCase.notes = (
      <NotesField
        caseId={singleCase.caseId}
        notes={singleCase.dashboard_notes}
      />
    );
    singleCase.comments = (
      <CommentsField
        caseId={singleCase.caseId}
        comments={singleCase.dashboard_comments}
      />
    );
    singleCase.manualeUsable = (
      <ManualeUsable
        caseId={singleCase.caseId}
        manualeUsable={singleCase.manualeUsable}
      />
    );
    singleCase.usable = (
      <AutomaticUsable caseId={singleCase.caseId} usable={singleCase.usable} />
    );
    singleCase.edit = <EditBtn changeRowtype={changeRowtype} />;
  }

  if (_rowType === "edit") {
    singleCase.notes = (
      <ChangeNotesField
        caseId={singleCase.caseId}
        notes={singleCase.dashboard_notes}
        handleChange={handleNotesChange}
      />
    );

    singleCase.comments = (
      <ChangeCommentsField
        caseId={singleCase.caseId}
        handleChange={handleCommentsChange}
      />
    );
    singleCase.manualeUsable = (
      <ChangeManualUsability
        caseId={singleCase.caseId}
        handleChange={handleDataQualityChange}
        manualeUsable={singleCase.manualeUsable}
      />
    );
    singleCase.edit = (
      <>
        <CancelBtn changeRowtype={changeRowtype} />
        <SaveBtn save={saveCaseInfo} />
      </>
    );
  }

  let rowItems = newDatatableColumns.map((col, index) => {
    return <td key={index}>{singleCase[col.field]}</td>;
  });

  return rowItems;
};

const NotesField = (props) => {
  let { notes } = props;
  return <p>{notes}</p>;
};

const CommentsField = (props) => {
  let { comments } = props;
  return <p>{comments}</p>;
};

const ManualeUsable = (props) => {
  let { manualeUsable } = props;
  return (
    <>
      <p>
        {manualeUsable}
        {/* {paid == true ? "Paid" : "Not Paid"} */}
      </p>
    </>
  );
};

const AutomaticUsable = (props) => {
  let { usable } = props;

  return (
    <>
      <p>
        {usable ? "Usable" : "Unusable"}
        {/* {paid == true ? "Paid" : "Not Paid"} */}
      </p>
    </>
  );
};

const ChangeNotesField = (props) => {
  let { notes, handleChange } = props;
  return <textarea rows="4" defaultValue={notes} onChange={handleChange} />;
};

const ChangeCommentsField = (props) => {
  let { handleChange } = props;
  return (
    <>
      <Select
        options={commentsOptions}
        onChange={handleChange}
        classNamePrefix="customSelect"
      />
    </>
  );
};

const ChangeManualUsability = (props) => {
  let { handleChange, manualeUsable } = props;

  return (
    <>
      <Select
        options={dataQualityOptions}
        onChange={handleChange}
        classNamePrefix="customSelect"
        defaultValue={manualeUsable}
      />
    </>
  );
};

const DatatableActions = (props) => {
  let { caseLink, caseId } = props;
  let link = "";
  if (caseLink) {
    link = caseLink
      .split("= '")
      .pop()
      .split("';")[0];
  }

  return (
    <>
      <a href={`https://cloud.clarius.com${link}`}>
        <button className="btn btn-green" size="sm">{`Clarius view`}</button>
      </a>
      <Link to={`/cases/${caseId}`}>
        <button className="btn btn-green" size="sm">{`Dashboard view `}</button>
      </Link>
    </>
  );
};

const EditBtn = (props) => {
  let { changeRowtype } = props;
  return (
    <>
      <Link to="#" onClick={() => changeRowtype("edit")}>
        <button className="btn btn-green" size="sm">{`Edit`}</button>
      </Link>
    </>
  );
};

const CancelBtn = (props) => {
  let { changeRowtype } = props;
  return (
    <>
      <Link to="#" onClick={() => changeRowtype("info")}>
        <button className="btn btn-red" size="sm">{`Cancel`}</button>
      </Link>
    </>
  );
};

const SaveBtn = (props) => {
  let { save } = props;
  return (
    <>
      <Link to="#">
        <button
          className="btn btn-blue"
          size="sm"
          onClick={save}
        >{`Save`}</button>
      </Link>
    </>
  );
};

export default React.memo(BasicTableRow);
// export default TableRow;
