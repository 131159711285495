import { combineReducers } from "redux";

// My Reducers
import { authReducer } from "./authReducer";
import { deviceReducer } from "./deviceReducer";
import { caseReducer } from "./caseReducer";
import { clariusScrapper } from "./clairusScrapperReducer";

// Training Reducers
import { moduleReducer } from "./Training/moduleReducer";

// get all reducers and link it to the state
export default combineReducers({
  user: authReducer,
  devices: deviceReducer,
  cases: caseReducer,
  clariusScrapper: clariusScrapper,
  t_modules: moduleReducer
});
