import React from "react"

import {
  NumberBoxComponent,
  PercentageCircleProgressBox,
  DataQualityChart,
  PaidUsableCasesChart,
  CircleProgressBox,
  DiseaseChartBox,
  CasesGraph,
} from "./BoxComponent"

/**
 * 1- diseases
 * 2- usabale
 * 3- unusable
 */
class ReportsComponent extends React.Component {
  render() {
    let {
      selectedCountry,
      totalPatients,
      totalUsable,
      totalUnusable,
      totalPending,
      totalPaid,
      totalDataQualityChart,

      patients,
      usable,
      unusable,
      pending,
      paid,
      dataQualityChart,
      paidUsableCasesChart,
      diseasesGraph,
      comments_count,
      dataBar,
      paidCasesCount,
    } = this.props

    let usabilityForOncoustics = parseInt((usable / (patients - pending)) * 100)

    let totalUsabilityForOncoustics = parseInt(
      (totalUsable / (totalPatients - totalPending)) * 100
    )

    // let patientCompletedUntiNextPayment = 100 - (usable - paid);

    console.log(totalPatients, usable, unusable, pending, paid)
    return (
      <>
        <div className="row">
          {this.props.children}
          {/* Patients */}
          <NumberBoxComponent
            title={"Patients"}
            number={patients}
            percentage={0}
          />
          {/* Data Quality */}
          <DataQualityChart
            title="Data Quality"
            chartData={dataQualityChart}
            footer={""}
            innerCount={patients}
          />
          {/* Usability for */}
          <PercentageCircleProgressBox
            title="Usability For Oncoustics"
            percentage={usabilityForOncoustics}
            footer="Average"
          />
          {/* Comments */}
          <div class="col-xl-12 col-md-12">
            <div className="stats-comments">
              <h2>Comments</h2>
              <ul className="dashboard-comments-list">
                {Object.keys(comments_count).map((key) => {
                  return (
                    <li key={key}>
                      {" "}
                      {comments_count[key]} {key.replace("_", " ")}{" "}
                    </li>
                  )
                })}
              </ul>
              <hr />
            </div>
          </div>
          {/* Timestamp */}
          <div class="col-xl-12 col-md-12">
            <h2 className="stats-time-seprator">All</h2>
          </div>
          {/* Patients */}
          <NumberBoxComponent
            title={"Patients"}
            number={totalPatients}
            percentage={0}
          />
          {/* Data Quality */}
          <DataQualityChart
            title="Data Quality"
            chartData={totalDataQualityChart}
            footer={""}
            innerCount={totalPatients}
          />
          {/* usability for Oncoustics */}
          <PercentageCircleProgressBox
            title="Usability For Oncoustics"
            percentage={totalUsabilityForOncoustics}
            color="red"
            footer="Average"
          />
          {/* Paid usable Cases */}
          <div className="row">
            <PaidUsableCasesChart
              title="Usable Cases"
              chartData={paidUsableCasesChart}
              footer={""}
              innerCount={usable}
            />
          </div>
          <div className="row">
            {/* patient Completed until next payment */}
            {/* <CircleProgressBox
            innerText={100}
            title="Patients Completed Until Next Payment"
            value={patientCompletedUntiNextPayment}
            total={100}
            strokeColor={{
              "0%": "#08476A",
              "100%": "#E07CEE",
            }}
            color="black"
            footer="Patients Remaining vs Patients Completed"
          /> */}

            {/* <div class="col-xl-4 col-md-6 empty-box"></div>
          <div class="col-xl-4 col-md-6 empty-box"></div> */}
            {/* Disease */}
            <DiseaseChartBox
              title="Diseases"
              diseasesGraph={diseasesGraph}
              footer={""}
            />
            {/* Patients scanned */}
            <CasesGraph title="Patients Scanned" data={dataBar} />
          </div>
        </div>
      </>
    )
  }
}
export default ReportsComponent
