import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  cases: [],
  case: null,
  error_message: [],
  hasError: false,
  loading: false,
  lastSyncDate: ""
};

export function clariusScrapper(state = initialState, action) {
  switch (action.type) {
    //******************* Devices ********************\\
    case actionTypes.FETCHING_CLARIUS_CASES:
      return {
        ...state,
        cases: [],
        case: null,
        loading: true,
        hasError: false,
        error_message: []
      };

    case actionTypes.GET_CLARIUS_CASES_ERROR:
      return {
        ...state,
        saved: false,
        cases: []
      };

    case actionTypes.GET_CLARIUS_CASES_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        empty: false,
        cases: action.cases,
        lastSyncDate: action.lastSyncDate
      };

    //******************* Device ********************\\
    case actionTypes.FETCHING_CLARIUS_CASE:
      return {
        ...state,
        loading: true,
        hasError: false,
        error_message: []
      };

    case actionTypes.GET_CLARIUS_CASE_ERROR:
      return {
        ...state,
        saved: false,
        hasError: true,
        error_message: action.err,
        case: null
      };

    case actionTypes.GET_CLARIUS_CASE_SUCCESS:
      return {
        ...state,
        saved: true,
        empty: false,
        case: action.device
      };

    default:
      return { ...state };
  }
}
