import React from "react";
import { Link } from "react-router-dom";
import store2 from "store2";

const type = store2.get("type");
console.log("Type Is:", type);
const Sidebar = () => {
  if (type === "doctor") {
    return (
      <div className="site-menubar">
        <div className="site-menubar-body">
          <ul className="site-menu" data-plugin="menu">
            <li className="site-menu-category">Menu</li>
            <li className="site-menu-item  ">
              <a className="animsition-link" href="/site">
                <i
                  className="site-menu-icon md-view-dashboard"
                  aria-hidden="true"
                />
                <span className="site-menu-title">HomePage</span>
              </a>
            </li>
            <li className="site-menu-item has-sub  ">
              <Link to="/site/profile">
                <i
                  className="site-menu-icon md-view-compact"
                  aria-hidden="true"
                />
                <span className="site-menu-title">Profile </span>
              </Link>
            </li>
            <li className="site-menu-item has-sub  ">
              <Link to="/site/reports">
                <i
                  className="site-menu-icon md-view-compact"
                  aria-hidden="true"
                />
                <span className="site-menu-title">Reports </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  if (type === "site") {
    return (
      <div className="site-menubar">
        <div className="site-menubar-body">
          <ul className="site-menu" data-plugin="menu">
            <li className="site-menu-category">Menu</li>
            <li className="site-menu-item has-sub  ">
              <Link to="/site/reports">
                <i
                  className="site-menu-icon md-view-compact"
                  aria-hidden="true"
                />
                <span className="site-menu-title">Reports </span>
              </Link>
            </li>
            <li className="site-menu-item has-sub  ">
              <Link to="/site/profile">
                <i
                  className="site-menu-icon md-view-compact"
                  aria-hidden="true"
                />
                <span className="site-menu-title">Profile </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className="site-menubar">
      <div className="site-menubar-body">
        <ul className="site-menu" data-plugin="menu">
          <li className="site-menu-category">Menu</li>
          <li className="site-menu-item  ">
            <a className="animsition-link" href="/">
              <i
                className="site-menu-icon md-view-dashboard"
                aria-hidden="true"
              />
              <span className="site-menu-title">HomePage</span>
            </a>
          </li>
          <li className="site-menu-item has-sub  ">
            <Link to="/devices">
              <i
                className="site-menu-icon md-view-compact"
                aria-hidden="true"
              />
              <span className="site-menu-title">Devices</span>
            </Link>
          </li>
          <li className="site-menu-item has-sub  ">
            <Link to="/cases/clarius">
              <i
                className="site-menu-icon md-view-compact"
                aria-hidden="true"
              />
              <span className="site-menu-title">Clarius Cases</span>
            </Link>
          </li>

          <li className="site-menu-item has-sub  ">
            <Link to="/cases/combined">
              <i
                className="site-menu-icon md-view-compact"
                aria-hidden="true"
              />
              <span className="site-menu-title">Combined Cases</span>
            </Link>
          </li>
          <li className="site-menu-item has-sub  ">
            <Link to="/cases/quality-checks">
              <i
                className="site-menu-icon md-view-compact"
                aria-hidden="true"
              />
              <span className="site-menu-title">Quality Checks</span>
            </Link>
          </li>
          <li className="site-menu-item has-sub  ">
            <Link to="/payments">
              <i
                className="site-menu-icon md-view-compact"
                aria-hidden="true"
              />
              <span className="site-menu-title">Payments</span>
            </Link>
          </li>
          <li className="site-menu-item has-sub  ">
            <Link to="/accounts">
              <i
                className="site-menu-icon md-view-compact"
                aria-hidden="true"
              />
              <span className="site-menu-title">Accounts</span>
            </Link>
          </li>
          <li className="site-menu-item has-sub  ">
            <Link to="/reports">
              <i
                className="site-menu-icon md-view-compact"
                aria-hidden="true"
              />
              <span className="site-menu-title">Reports</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
