import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Mentions, Input, Spin, Progress } from "antd";
import DatatTableComponent from "../Partials/_DatatableComponet";
import { MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../Store/Apis/BaseUrl";
import "../Partials/antd.css";

// Components
import FooterComponent from "../Partials/_FooterComponent";
import HeaderComponent from "../Partials/_HeaderComponent";
import SidebarComponent from "../Partials/_SidebarComponent";
import DeviceLoaderComponent from "../Loaders/_DeviceLoaderComponent";
import DeviceLatestCasesLoader from "../Loaders/_DeviceLatestCasesLoader";

// Helpers
import { dateFormat } from "../../Helpers/dateHelper";
// Actions
import * as deviceActions from "../../Store/Actions/deviceActions";

function mapStateToProps(store) {
  return {
    devicesStore: store.devices
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deviceActions: bindActionCreators(deviceActions, dispatch)
  };
}

const { Option } = Mentions;
const notificationTypes = [
  "sync",
  "openTV",
  "openPD",
  "backupAllToSd",
  "backupAllToStorage",
  "backupCaseToSd",
  "backupCaseToStorage",
  "uploadAll",
  "uploadCase",
  "deleteAll",
  "deleteCase"
];

const datatableComponent = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 150
  },
  {
    label: "Case ID",
    field: "caseId",
    sort: "asc",
    width: 150
  },
  {
    label: "Files Count",
    field: "filesCount",
    sort: "asc",
    width: 150
  },
  {
    label: "Clarius  Count",
    field: "clariusCount",
    sort: "asc",
    width: 150
  },
  {
    label: "S3 Count",
    field: "s3Count",
    sort: "asc",
    width: 150
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 150
  }
];

const DatatableSyncActions = props => (
  <Link to={`#`}>
    <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
    <MDBBtn
      color="red"
      size="sm"
      onClick={() => props.syncCase(props.caseId)}
    >{`Sync `}</MDBBtn>
  </Link>
);

const DatatableViewActions = props => (
  <Link to={`#`}>
    <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
  </Link>
);

const actionBtn = (singleCase, syncCase) => {
  if (singleCase.status === "syncing_with_s3") {
    return (
      <Link to={`#`}>
        <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
        <MDBBtn color="green" size="sm">{`syncing with S3`}</MDBBtn>
      </Link>
    );
  }
  if (singleCase.status === "syncing") {
    return (
      <Link to={`#`}>
        <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
        <MDBBtn color="green" size="sm">{`In Queue `}</MDBBtn>
      </Link>
    );
  }
  if (singleCase.status === "syncing_videos") {
    return (
      <Link to={`#`}>
        <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
        <MDBBtn color="green" size="sm">{`Syncing Videos.. `}</MDBBtn>
      </Link>
    );
  }
  if (singleCase.status === "syncing_tars") {
    return (
      <Link to={`#`}>
        <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
        <MDBBtn color="green" size="sm">{`Syncing tars.. `}</MDBBtn>
      </Link>
    );
  }
  if (singleCase.fileNames.length !== singleCase.s3Files.length) {
    return (
      <DatatableSyncActions syncCase={syncCase} caseId={singleCase.caseId} />
    );
  }
  return <DatatableViewActions caseId={singleCase.caseId} />;
};

const modelImages = {
  "SM-J737U": "https://fdn2.gsmarena.com/vv/bigpic/samsung-galaxy-j7-2018.jpg",
  "JSN-L23": "https://fdn2.gsmarena.com/vv/bigpic/huawei-honor-8x.jpg",
  ONEPLUSA6013:
    "https://fdn2.gsmarena.com/vv/bigpic/oneplus-6t-thunder-purple.jpg"
};

class SingleDeviceComponent extends React.Component {
  state = {
    showCaseIdInput: false,
    notificationAction: null,
    notificationCaseId: null
  };

  componentDidMount() {
    const { deviceId } = this.props.match.params;
    this.props.deviceActions.getDevice(deviceId);
    this.props.deviceActions.getDeviceLatestCasesInfo(deviceId);

    document.body.className = "animsition page-profile-v2 site-menubar-unfold";
  }

  onNotificationListChange = value => {
    console.log("Change:", value);
  };

  onCaseIdChange = input => {
    this.setState({
      notificationCaseId: input.target.value
    });
  };

  onNotificationListSelect = option => {
    const actionsNeedCaseId = [
      "backupCaseToSd",
      "backupCaseToStorage",
      "uploadCase",
      "deleteCase"
    ];
    if (actionsNeedCaseId.includes(option.value)) {
      this.setState({
        showCaseIdInput: true,
        notificationAction: option.value
      });
    } else {
      this.setState({
        showCaseIdInput: false,
        notificationAction: option.value,
        notificationCaseId: null
      });
    }
  };

  sendNotification = () => {
    const { notificationAction, notificationCaseId } = this.state;
    const firebaseToken = this.props.devicesStore.device.deviceToken;
    this.props.deviceActions.sendNotification(
      firebaseToken,
      notificationAction,
      notificationCaseId
    );
  };

  syncCase = caseId => {
    axios
      .get(`${baseUrl}/case/${caseId}/sync`)
      .then(res => {
        window.location.reload();
      })
      .catch(err => {
        return err;
      });
  };

  render() {
    const { device, error_message } = this.props.devicesStore;

    if (!device) {
      return <DeviceLoaderComponent error={error_message} />;
    }

    let lastSync, lastCreatedCase, batteryUsage, storageSpace, storagePercntage;

    if (device) {
      const latestUsages = device.deviceUsages[device.deviceUsages.length - 1];
      batteryUsage = latestUsages.batteryUsage;

      storageSpace = latestUsages.storageSpace;
      storagePercntage = (storageSpace.used / storageSpace.total) * 100;

      let storageCases = latestUsages.storageCases;

      lastSync = dateFormat(device.updatedAt);
      storageCases.length
        ? (lastCreatedCase = storageCases[storageCases.length - 1].id)
        : (lastCreatedCase = null);
    }

    const rows = this.props.devicesStore.latest_cases_info.map(singleCase => {
      return {
        date: singleCase.createdAt,
        caseId: singleCase.caseId,
        filesCount: singleCase.fileNames.length,
        clariusCount: singleCase.clariusFiles.length,
        s3Count: singleCase.s3Files.length,
        actions: actionBtn(singleCase, this.syncCase)
      };
    });
    return (
      <>
        <HeaderComponent />
        <SidebarComponent />
        <div className="page">
          <div className="page-content container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="user-info card card-shadow text-xs-center">
                  <div className="user-base card-block">
                    <a className="avatar img-bordered avatar-100" href="/">
                      <img
                        src={modelImages[device.model.replace(/\s/g, "")]}
                        alt="..."
                      />
                    </a>
                    <br />
                    <h4 className="user-name">{device.deviceName}</h4>
                    {/* <p className="user-job">PhD in Neurology</p> */}
                    {/* <p className="user-location">China </p> */}
                  </div>
                  <div className="user-socials list-group list-group-full">
                    <a className="list-group-item" href="/">
                      <i className="icon"></i> last sync: {lastSync}
                    </a>
                    <a className="list-group-item" href="/">
                      <i className="icon"></i>last created case:{" "}
                      {lastCreatedCase}
                    </a>
                  </div>

                  <div className="card-footer">
                    <div className="row no-space">
                      <div className="col-xs-4">
                        <strong className="profile-stat-count">
                          {device.casesCount}
                        </strong>
                        <span>Total Casess</span>
                      </div>
                      <div className="col-xs-4">
                        <strong className="profile-stat-count">
                          {device.completedCasesCount}
                        </strong>
                        <span>Completed Cases</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="row">
                  {/* 3rd:  Notifications*/}
                  <div className="col-lg-12 col-xl-12  col-xs-12">
                    <div className="card user-visitors">
                      <div className="card-header card-header-transparent p-20">
                        <h4 className="card-title m-b-0">Actions </h4>
                      </div>
                      <div className="card-block">
                        <ul
                          id="action-list"
                          className="list-group list-group-full"
                        >
                          <li className="list-group-item">
                            <div className="media">
                              <div className="media-left"></div>
                              <div className="media-body">
                                <h4 className="media-heading hover">Backup</h4>
                                <Mentions
                                  style={{ width: "100%" }}
                                  onChange={this.onNotificationListChange}
                                  onSelect={this.onNotificationListSelect}
                                  defaultValue="@"
                                >
                                  {notificationTypes.map(action => (
                                    <Option
                                      key={`action-${action}`}
                                      value={action}
                                    >
                                      {action}
                                    </Option>
                                  ))}
                                </Mentions>
                              </div>
                            </div>
                          </li>

                          {this.state.showCaseIdInput && (
                            <li className="list-group-item" id="caseId-input">
                              <div className="media">
                                <div className="media-left"></div>
                                <div className="media-body">
                                  <h4 className="media-heading hover">
                                    Type Case Id
                                  </h4>
                                  <Input
                                    onChange={this.onCaseIdChange}
                                    onPressEnter={this.sendNotification}
                                    placeholder="case-2-23sfed32ed"
                                  />
                                </div>
                              </div>
                            </li>
                          )}
                        </ul>

                        {this.props.devicesStore.sending_notification ? (
                          <Spin size="small">
                            <div class="text-xs-center">
                              <button
                                onClick={this.sendNotification}
                                class="btn btn-round btn-outline btn-primary waves-effect"
                                type="button"
                                name="button"
                              >
                                Send Action
                              </button>
                            </div>
                          </Spin>
                        ) : (
                          <div class="text-xs-center">
                            <button
                              onClick={this.sendNotification}
                              class="btn btn-round btn-outline btn-primary waves-effect"
                              type="button"
                              name="button"
                            >
                              Send Action
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Mobile Stats */}
                  <div className="col-xs-12 col-lg-12 col-xl-12 ">
                    <div className="user-background card card-shadow">
                      <div className="card-header card-header-transparent p-20">
                        <div className="row">
                          {/*  Statistics*/}
                          <div className="col-xs-12 col-lg-12 col-xl-9">
                            <h4 className=" mobile-status card-title m-b-0">
                              Mobile Status:
                            </h4>
                            <div className="card-block">
                              <div className="row">
                                <div className="col-xs-12 col-lg-4 col-xl-4">
                                  <h5 className="mobile-status-title card-title m-b-0">
                                    Battery Usage
                                  </h5>
                                  <Progress
                                    type="circle"
                                    percent={batteryUsage}
                                    strokeColor="green"
                                  />
                                </div>
                              </div>
                              <div className="col-xs-12 col-lg-10 col-xl-10">
                                <h5 className="card-title m-b-0">
                                  Storage Space
                                </h5>
                                <Progress percent={storagePercntage} />{" "}
                                <span></span>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                          {/*  Image*/}
                          <div className="col-xs-12 col-lg-12 col-xl-3 ">
                            <h4 className="mobile-status card-title m-b-0">
                              {device.model}
                            </h4>
                            <div className="card-block">
                              <img
                                src={
                                  modelImages[device.model.replace(/\s/g, "")]
                                }
                                alt="mobile model"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {/* Storage Files */}
              <div className="col-xs-12 col-lg-12 col-xl-12 ">
                <div className="user-background card card-shadow">
                  {this.props.devicesStore.latest_cases_info_loading ? (
                    <DeviceLatestCasesLoader />
                  ) : (
                    <>
                      <div className="card-header card-header-transparent p-20">
                        <h4 className="card-title m-b-0">Storage Files</h4>
                      </div>
                      <div className="card-block">
                        <ul className="timeline timeline-single">
                          <li className="timeline-item">
                            <span className="block m-b-5">
                              Search for a case or file:{" "}
                            </span>
                            <DatatTableComponent
                              columns={datatableComponent}
                              rows={rows}
                            />
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleDeviceComponent);
