import React, { useState } from "react"

import { Progress } from "antd"
import { DatePicker, Spin } from "antd"
import { ResponsivePie } from "@nivo/pie"
import { Bar } from "react-chartjs-2"

// import { linearGradientDef } from "@nivo/core";
// import { Stream } from "@nivo/stream";

export const NumberBoxComponent = (props) => {
  const { title, number, percentage } = props
  return (
    <div class="col-xl-4 col-md-6">
      <div class="card card-shadow stats-box" id="widgetLineareaTwo">
        <div class="card-block p-20 p-t-10">
          <div class="clearfix">
            <div class="grey-800 pull-xs-left p-y-10">{title}</div>
          </div>
          <div className="stats-box-info">{number}</div>
          <div className="stats-box-footer">
            <span>{percentage}%</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PercentageCircleProgressBox = (props) => {
  const { title, percentage, color, footer } = props
  return (
    <div class="col-xl-4 col-md-6">
      <div class="card card-shadow stats-box" id="widgetLineareaTwo">
        <div class="card-block ">
          <div class="clearfix">
            <div class="grey-800 pull-xs-left p-y-10">{title}</div>
          </div>
          <div className="stats-box-info">
            <Progress
              strokeWidth={15}
              strokeLinecap="square"
              type="circle"
              strokeColor={{
                "0%": "#08476A",
                "100%": "#E07CEE",
              }}
              //   width={100}
              percent={percentage}
            />
          </div>
          <div className="stats-box-footer">
            <span className="average-indicator" />
            <p>{footer}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CircleProgressBox = (props) => {
  const { title, value, total, footer, strokeColor, innerText } = props
  return (
    <div class="col-xl-4 col-md-6">
      <div class="card card-shadow stats-box" id="widgetLineareaTwo">
        <div class="card-block p-20 p-t-10">
          <div class="clearfix">
            <div class="grey-800 pull-xs-left p-y-10">{title}</div>
          </div>
          <div className="stats-box-info">
            <Progress
              strokeWidth={17}
              strokeLinecap="square"
              type="circle"
              strokeColor={strokeColor}
              width={100}
              percent={(value / total) * 100}
              format={() => innerText}
            />
          </div>
          <div className="stats-box-footer">
            <p>{footer}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
const margin = { top: 30, right: 200, bottom: 30, left: 30 }
const responsivePieStyles = {
  root: {
    // fontFamily: "consolas, sans-serif",
    textAlign: "center",
    position: "relative",
    height: 130,
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    color: "black",
    textAlign: "center",
    // This is important to preserve the chart interactivity
    pointerEvents: "none",
  },
}

const bieChartColors = [
  {
    id: "gradientGray",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#F3F3F3" },
      { offset: 100, color: "#F3F3F3" },
    ],
  },
  {
    id: "gradientPurple",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#08476a" },
      { offset: 100, color: "#e07cee" },
    ],
  },
  {
    id: "gradientGreen",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#094B70" },
      { offset: 100, color: "#7EF090" },
    ],
  },
  {
    id: "gradientRed",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#FBA714" },
      { offset: 100, color: "#F42222" },
    ],
  },
  {
    id: "gradientYellow",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#FBFB14" },
      { offset: 100, color: "#FBA714" },
    ],
  },
  {
    id: "gradientBlue",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#969AFF" },
      { offset: 100, color: "#1400FF" },
    ],
  },
  {
    id: "piggyPink",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#ffdde1" },
      { offset: 100, color: "#ee9ca7" },
    ],
  },
  {
    id: "coolBlues",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#6dd5ed" },
      { offset: 100, color: "#2193b0" },
    ],
  },
  {
    id: "jShine",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#12c2e9" },
      { offset: 100, color: "#c471ed" },
    ],
  },
  {
    id: "yoda",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#FF0099" },
      { offset: 100, color: "#493240" },
    ],
  },
  {
    id: "harvey",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#c31432" },
      { offset: 100, color: "#240b36" },
    ],
  },
  {
    id: "flare",
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#f5af19" },
      { offset: 100, color: "#f12711" },
    ],
  },
]
export const DataQualityChart = (props) => {
  const { title, chartData, footer, innerCount } = props
  console.log("DATA QUALITY gradientGray: ", title, chartData)
  let countOfEmptyValues = 0
  if (Array.isArray(chartData)) {
    chartData.forEach((c) => {
      if (c.value === 0) {
        countOfEmptyValues += 1
      }
    })
    if (countOfEmptyValues === chartData.length) {
      chartData.push({ id: "empty", label: "empty", value: true })
    }
  }
  console.log("countOfEmptyValues")
  console.log(countOfEmptyValues)
  console.log("chartData")
  console.log(chartData)
  return (
    <div class="col-xl-4 col-md-6">
      <div class="card card-shadow stats-box" id="widgetLineareaTwo">
        <div class="card-block p-20 p-t-10">
          <div class="clearfix">
            <div class="grey-800 pull-xs-left p-y-10">{title}</div>
          </div>

          <div
            className="stats-box-info"
            style={responsivePieStyles.root}
            // style={{ height: 100 }}
          >
            <ResponsivePie
              // layers={[CenteredMetric]}
              data={chartData}
              margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
              fit={true}
              //   innerRadius={0.85}
              //   padAngle={1}
              innerRadius={0.6}
              cornerRadius={0}
              colors={{ scheme: "nivo" }}
              borderWidth={0}
              isInteractive={false}
              enableRadialLabels={false}
              enableSliceLabels={true}
              sliceLabel="value"
              sliceLabelsSkipAngle={10}
              sliceLabelsTextColor="white"
              defs={bieChartColors}
              fill={[
                {
                  match: {
                    id: "empty",
                  },
                  id: "gradientGray",
                },
                {
                  match: {
                    id: "usable",
                  },
                  id: "gradientGreen",
                },
                {
                  match: {
                    id: "unusable",
                  },
                  id: "gradientRed",
                },
                {
                  match: {
                    id: "pending",
                  },
                  id: "gradientYellow",
                },
              ]}
            />
            <div style={responsivePieStyles.overlay}>
              <span>{innerCount}</span>
            </div>
          </div>
          <div className="stats-box-footer">
            <p>
              {" "}
              <span className="usable-indicator" />
              Usable
            </p>
            <p>
              <span className="unusable-indicator" />
              Unusable
            </p>
            <p>
              {" "}
              <span className="pending-indicator" />
              Pending
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PaidUsableCasesChart = (props) => {
  const { title, chartData, innerCount, footer } = props
  let countOfEmptyValues = 0
  console.log("PaidUsableCasesChart", chartData)
  if (Array.isArray(chartData)) {
    chartData.forEach((c) => {
      if (c.value === 0) {
        countOfEmptyValues += 1
      }
    })

    if (countOfEmptyValues === chartData.length) {
      chartData.push({
        id: "empty",
        label: "empty",
        value: 100,
      })
    }
  }
  return (
    <div class="col-xl-4 col-md-6">
      <div class="card card-shadow stats-box" id="widgetLineareaTwo">
        <div class="card-block p-20 p-t-10">
          <div class="clearfix">
            <div class="grey-800 pull-xs-left p-y-10">{title}</div>
          </div>

          <div className="stats-box-info" style={responsivePieStyles.root}>
            <ResponsivePie
              data={chartData}
              // margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
              fit={true}
              //   innerRadius={0.85}
              //   padAngle={1}
              innerRadius={0.5}
              cornerRadius={0}
              colors={{ scheme: "nivo" }}
              borderWidth={0}
              isInteractive={false}
              enableRadialLabels={false}
              enableSliceLabels={true}
              sliceLabel="value"
              sliceLabelsSkipAngle={10}
              sliceLabelsTextColor="white"
              defs={bieChartColors}
              fill={[
                {
                  match: {
                    id: "empty",
                  },
                  id: "gradientGray",
                },
                {
                  match: {
                    id: "usable",
                  },
                  id: "gradientGreen",
                },
                {
                  match: {
                    id: "paid",
                  },
                  id: "gradientBlue",
                },
              ]}
              margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
            />
            <div style={responsivePieStyles.overlay}>
              <span>{innerCount}</span>
            </div>
          </div>

          <div className="stats-box-footer">
            <p>
              {" "}
              <span className="paid-indicator" /> Total Patients Paid
            </p>
            <p>
              <span className="usable-indicator" />
              Patients Pending Payment
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export const DiseaseChartBox = (props) => {
  const { title, footer, diseasesGraph } = props

  let fill = diseasesGraph.map((d, index) => {
    let defsColor = bieChartColors[index % bieChartColors.length]
    let defId = defsColor ? defsColor.id : ""
    return {
      match: {
        id: d.id,
      },
      id: defId,
      color1: defsColor ? defsColor.colors[0].color : "",
      color2: defsColor ? defsColor.colors[1].color : "",
    }
  })

  return (
    <div class="col-xl-4 col-md-6">
      <div class="card card-shadow stats-box" id="widgetLineareaTwo">
        <div class="card-block p-20 p-t-10">
          <div class="clearfix">
            <div class="grey-800 pull-xs-left p-y-10">{title}</div>
          </div>

          <div className="stats-box-info" style={{ height: 300 }}>
            <ResponsivePie
              data={diseasesGraph}
              margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
              fit={true}
              innerRadius={0}
              padAngle={0}
              cornerRadius={0}
              sortByValue={true}
              borderWidth={0}
              colors={{ scheme: "nivo" }}
              enableSliceLabels={false}
              sliceLabel={function(e) {
                return e.id + " (" + e.value + ")"
              }}
              isInteractive={false}
              enableRadialLabels={false}
              defs={bieChartColors}
              fill={fill}
            />
          </div>
          <div className="stats-box-footer">
            {fill.map((d) => {
              let diseaseObject = diseasesGraph.find(
                (obj) => d.match.id === obj.id
              )
              return (
                <p>
                  {" "}
                  <span
                    className="legendIndicator"
                    style={{
                      background: `linear-gradient(90deg, ${d.color1} 0%, ${d.color2} 100%)`,
                    }}
                  />{" "}
                  {`${diseaseObject ? diseaseObject.id : d.id ? d.id : ""} (${
                    diseaseObject ? diseaseObject.value : d.value ? d.value : ""
                  }) `}
                </p>
              )
            })}

            {/* <p>
              <span className="unusable-indicator" />
              Unsable
            </p>
            <p>
              {" "}
              <span className="paid-indicator" />
              Paid
            </p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export const CasesGraph = (props) => {
  const { title, data } = props
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      xAxes: [
        {
          barPercentage: 1,
          gridLines: {
            display: false,
            color: "rgba(0, 0, 0, 0.1)",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            color: "rgba(0, 0, 0, 0.1)",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }
  return (
    <div class="col-xl-8 col-md-8">
      <div class="card card-shadow stats-box" id="widgetLineareaTwo">
        <div class="card-block p-20 p-t-10">
          <div class="clearfix">
            <div class="grey-800 pull-xs-left p-y-10">{title}</div>
          </div>
          <div className="stats-box-info">
            <Bar data={data} options={barChartOptions} />
          </div>
          {/* <div className="stats-box-footer">
            <p>average</p>
          </div> */}
        </div>
      </div>
    </div>
  )
}
