import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import store2 from "store2";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

import LoginComponent from "./Components/Auth/LoginComponent";
import ForgetPassword from "./Components/Auth/ForgetPassword";
import RegisterComponent from "./Components/Auth/RegisterComponent";

import DevicesComponent from "./Components/Devices/ListComponent";
import SingleDeviceComponent from "./Components/Devices/SingleDeviceComponent";
import DeviceCasesComponent from "./Components/Devices/DeviceCasesComponent";
import HomepageComponent from "./Components/Homepage";
import CasesComponent from "./Components/CombinedCasesComponent/CasesComponent";
import ClariusCasesComponent from "./Components/CombinedCasesComponent/ClariusCases";
import CombinedCasesComponent from "./Components/CombinedCasesComponent";
import QualityChecksComponent from "./Components/QualityChecksComponent";
import Reports from "./Pages/Admin/Reports";

import SingleCaseComponent from "./Components/CombinedCasesComponent/SingleCaseComponent";
import TestComponent from "./Components/Training/Label/TestComponent";
import Payments from "./Components/Payments";
import AddNewPayment from "./Components/AddNewPayment";
import EditPayment from "./Components/EditPayment";

//=========================== Site Pages =================
import SitesReports from "./Pages/Sites/Reports";
import SitesHomepage from "./Pages/Sites/Homepage";
import Profile from "./Pages/Sites/Profile";

//=========================== Accounts Pages =================
import Accounts from "./Pages/Admin/Accounts";
import CreateAccounts from "./Pages/Admin/Accounts/Create";

// import DoctorsListComponent from "./Components/Doctors/ListComponent";
// import DoctorProfileComponent from "./Components/Doctors/DoctorProfileComponent";

// Training Website
// import CreateDoctorComponent from "./Components/Doctors/CreateComponent";
// import CreateModuleComponent from "./Components/TrainingWebsite/Modules/CreateComponent";
// import ListModulesComponent from "./Components/TrainingWebsite/Modules/ListComponent";
// import CreateQuestionComponent from "./Components/TrainingWebsite/Questions/CreateQuestionComponent";
// import TestExamComponent from "./Components/TrainingWebsite/Exams/TestExamComponent";

// import CreateTestQuestionComponent from "./Components/TrainingWebsite/TestModule/CreateQuestionComponent";
// import ListTestQuestionsComponent from "./Components/TrainingWebsite/TestModule/ListComponent";

// import "antd/dist/antd.css";

const NotFoundComponent = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Link to="/">
        <Button type="primary">Back Home</Button>
      </Link>
    }
  />
);

// const UnAuthorized = () => (
//   <div>
//     <h3>
//       You are not Authorized to see this page
//       <br />
//       {/*<code>{props.location.pathname}</code>*/}
//     </h3>
//     <br />
//   </div>
// );

const PrivateRoute = ({
  component: Component,
  auth,
  permission,
  permissions,
  ...rest
}) => {
  const token = store2.get("token");
  // const secret = "th!sIs0ncoust!csS3cr3t";
  if (!token) {
    return <Redirect to={"/login"} />;
  }
  if (token.length < 20) {
    store2.remove("token");
    return <Redirect to={"/login"} />;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const AuthRoute = ({ component: Component, auth, ...rest }) => {
  const token = store2.get("token");
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Redirect to={"/"} /> : <Component {...props} />
      }
    />
  );
};

const AppRouter = () => {
  return (
    <Switch>
      <AuthRoute exact path="/login" component={LoginComponent} />
      <AuthRoute exact path="/forget-password" component={ForgetPassword} />

      <AuthRoute exact path="/register" component={RegisterComponent} />

      {/* ============================== Site Routes=============== */}
      <PrivateRoute exact path="/site" component={SitesHomepage} />
      <PrivateRoute exact path="/site/reports" component={SitesReports} />
      <PrivateRoute exact path="/site/profile" component={Profile} />

      {/* ============================== END Site Routes=============== */}

      <PrivateRoute exact path="/" component={HomepageComponent} />
      <PrivateRoute exact path="/reports" component={Reports} />

      <PrivateRoute exact path="/devices" component={DevicesComponent} />
      <PrivateRoute exact path="/cases" component={CasesComponent} />
      <PrivateRoute
        exact
        path="/cases/clarius"
        component={ClariusCasesComponent}
      />
      <PrivateRoute
        exact
        path="/cases/combined"
        component={CombinedCasesComponent}
      />
      <PrivateRoute
        exact
        path="/cases/quality-checks"
        component={QualityChecksComponent}
      />

      <PrivateRoute exact path="/payments" component={Payments} />
      <PrivateRoute exact path="/add-new-payment" component={AddNewPayment} />
      <PrivateRoute
        exact
        path="/edit-payment/:paymentId"
        component={EditPayment}
      />

      <PrivateRoute
        exact
        path="/cases/:caseId"
        component={SingleCaseComponent}
      />
      <PrivateRoute
        exact
        path="/devices/:deviceId/"
        component={SingleDeviceComponent}
      />
      <PrivateRoute
        exact
        path="/devices/:deviceId/cases"
        component={DeviceCasesComponent}
      />

      {/* ==================== Transeducers  ================ */}
      <PrivateRoute exact path="/accounts" component={Accounts} />
      <PrivateRoute exact path="/accounts/create" component={CreateAccounts} />
      {/* ====================  END Transeducers  ================ */}

      {/* ==================== Training Website  ================ */}
      {/* <PrivateRoute
        exact
        path="/tw/modules/test"
        component={ListTestQuestionsComponent}
      />
      <PrivateRoute
        exact
        path="/tw/modules/test/create"
        component={CreateTestQuestionComponent}
      />

      <PrivateRoute exact path="/tw/modules" component={ListModulesComponent} />

      <PrivateRoute
        exact
        path="/tw/modules/create"
        component={CreateModuleComponent}
      />

      <PrivateRoute
        exact
        path="/tw/exams/warmup"
        component={TestExamComponent}
      /> */}
      <PrivateRoute exact path="/test-image/" component={TestComponent} />
      <Route component={NotFoundComponent} />
    </Switch>
  );
};

export default AppRouter;
