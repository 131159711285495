import * as actionTypes from "../../Actions/Training/actionTypes";

const initialState = {
  modules: [],
  module: null,
  error_message: [],
  hasError: false,
  loading: false
};

export function moduleReducer(state = initialState, action) {
  switch (action.type) {
    //******************* Modules ********************\\
    case actionTypes.FETCHING_MODULES:
      return {
        ...state,
        modules: [],
        module: null,
        loading: true,
        hasError: false,
        error_message: []
      };

    case actionTypes.GET_MODULES_ERROR:
      return {
        ...state,
        saved: false,
        modules: []
      };

    case actionTypes.GET_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        empty: false,
        modules: action.modules
      };

    //******************* Module ********************\\
    case actionTypes.FETCHING_MODULE:
      return {
        ...state,
        loading: true,
        hasError: false,
        error_message: []
      };

    case actionTypes.GET_MODULE_ERROR:
      return {
        ...state,
        saved: false,
        hasError: true,
        error_message: action.err,
        module: null
      };

    case actionTypes.GET_MODULE_SUCCESS:
      return {
        ...state,
        saved: true,
        empty: false,
        module: action.case
      };

    default:
      return { ...state };
  }
}
