import React from "react";

// Components
import FooterComponent from "./Partials/_FooterComponent";
import HeaderComponent from "./Partials/_HeaderComponent";
import SidebarComponent from "./Partials/_SidebarComponent";

class BaseComponent extends React.Component {
  render() {
    return (
      <>
        <HeaderComponent />
        <SidebarComponent />
        <div className="page">
          <div className="page-content">
            <div className="panel">
              <div className="panel-body">
                <div
                  className="row"
                  data-plugin="matchHeight"
                  data-by-row="true"
                >
                  <div className="error" />
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </>
    );
  }
}
export default BaseComponent;
