import React from "react";
import { DatePicker, Space, Select, Input } from "antd";
import NumericInput from "./NumericInput";

const { Option } = Select;
const PaymentRow = (props) => {
  let {
    row,
    index,
    sites,
    changeSite,
    changeDate,
    changeNoOfPatients,
    changeAmount,
    deleteRow,
    paymentsRows,
  } = props;
  if (row.active === 0) {
    return (
      <tr>
        <td>
          <Select
            disabled
            showSearch
            style={{ width: 200 }}
            placeholder="Select Site"
            optionFilterProp="children"
          >
            <Option value="china">China</Option>
            <Option value="Egypt">Egypt</Option>
            <Option value="torronto">Torronto</Option>
          </Select>
        </td>
        <td>
          <DatePicker disabled value={row.paymentDate} />
        </td>
        <td>
          <NumericInput
            disabled
            style={{ width: 120 }}
            value={row.NoOfPatients}
            onChange={() => this.onNumberChange}
          />
        </td>
        <td>
          <NumericInput
            disabled
            style={{ width: 120 }}
            value={row.amount}
            onChange={() => this.onNumberChange}
          />
        </td>
      </tr>
    );
  }
  return (
    <tr>
      <td>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select Site"
          optionFilterProp="children"
          onChange={(value) => changeSite(index, value)}
        >
          {sites.map((site, index) => {
            return <Option value={site._id}>{site.title}</Option>;
          })}
        </Select>
      </td>
      <td>
        <DatePicker onChange={(value) => changeDate(index, value)} />
      </td>
      <td>
        <NumericInput
          style={{ width: 120 }}
          defaultValue={row.NoOfPatients}
          //   value={row.NoOfPatients}
          onChange={(value) => changeNoOfPatients(index, value)}
        />
      </td>
      <td>
        <NumericInput
          style={{ width: 120 }}
          //   value={row.amount}
          onChange={(value) => changeAmount(index, value)}
        />
      </td>
      <td>
        <button
          style={{
            width: 120,
          }}
          disabled={paymentsRows.length === 1 ? true : false}
          className="btn btn-red"
          onClick={() => deleteRow(index)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default PaymentRow;
