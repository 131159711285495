import axios from "axios";
import { baseUrl } from "./BaseUrl";

class CaseApi {
  static cases() {
    return axios
      .get(`${baseUrl}/cases`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  static combinedCases() {
    return axios
      .get(`${baseUrl}/cases/combined`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  static singleCase(caseId) {
    return axios
      .get(`${baseUrl}/cases/${caseId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
}

export default CaseApi;
