import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

import axios from "axios";
import { baseUrl } from "../../Store/Apis/BaseUrl";

// Components
import BaseComponent from "../BaseComponent";
import PaymentRow from "./PaymentRow";
// Actions
import * as caseActions from "../../Store/Actions/caseActions";

function mapStateToProps(store) {
  return {
    cases: store.cases,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    caseActions: bindActionCreators(caseActions, dispatch),
  };
}

class CombinedCasesComponent extends React.Component {
  state = {
    loading: false,
    sites: [],
    paymentsRows: [
      {
        siteId: null,
        paymentDate: null,
        NoOfPatients: 10,
        amount: 10,
        active: 1,
        actions: null,
      },
    ],
  };

  componentDidMount() {
    const fetchData = async () => {
      const res = await axios.get(`${baseUrl}/sites`);
      const { success, sites } = res.data;
      if (success) {
        this.setState({
          loading: false,
          sites: sites,
        });
        console.log("sites:", sites);
      } else {
        console.log("Error Getting Payments ");
      }
    };

    fetchData();
  }

  submit = async () => {
    const res = await axios.post(`${baseUrl}/payments`, {
      payments: this.state.paymentsRows,
    });
    const { success, payments } = res.data;
    if (success) {
      window.location.replace("/payments");
    }
  };

  changeSite = (index, value) => {
    let payment_rows = this.state.paymentsRows;
    payment_rows[index].siteId = value;
    this.setState({
      paymentsRows: payment_rows,
    });
  };
  changeDate = (index, value) => {
    let payment_rows = this.state.paymentsRows;
    payment_rows[index].paymentDate = value;
    this.setState({
      paymentsRows: payment_rows,
    });
  };
  changeNoOfPatients = (index, value) => {
    let payment_rows = this.state.paymentsRows;
    payment_rows[index].NoOfPatients = value;
    this.setState({
      paymentsRows: payment_rows,
    });
  };
  changeAmount = (index, value) => {
    let payment_rows = this.state.paymentsRows;
    payment_rows[index].amount = value;
    this.setState({
      paymentsRows: payment_rows,
    });
  };

  addNewRow = () => {
    //   validate old rows first
    let new_payment_rows = this.state.paymentsRows.map((r) => {
      // r.active = 0;
      return {
        ...r,
      };
    });
    new_payment_rows.push({
      siteId: null,
      paymentDate: null,
      NoOfPatients: 10,
      amount: 10,
      active: 1,
      actions: null,
    });
    this.setState({
      paymentsRows: new_payment_rows,
    });
  };
  deleteRow = (indexToDelete) => {
    let new_payment_rows = this.state.paymentsRows.filter((r, i) => {
      if (indexToDelete != i) {
        return true;
      }
      return false;
    });
    console.log("delete Row", indexToDelete, new_payment_rows);

    this.setState({
      paymentsRows: new_payment_rows,
    });
  };

  render() {
    let { paymentsRows, loading, sites } = this.state;
    console.log("paymentsRows", paymentsRows);
    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h6 className="panel-title-small"> Add New Payments</h6>
          </header>

          <div className="row">
            <div className="col-md-12 filter-tab">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="table-title"> Payments</h3>
                </div>
              </div>
            </div>
            <div className="col-md-12 payments-table">
              <MDBTable bordered>
                <MDBTableHead>
                  <tr>
                    <th>Site</th>
                    <th>Date</th>
                    <th># of Patients</th>
                    <th>Amount</th>
                    <th>Actions</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {paymentsRows.map((row, index) => {
                    return (
                      <PaymentRow
                        paymentsRows={paymentsRows}
                        key={index}
                        row={row}
                        index={index}
                        sites={sites}
                        changeSite={this.changeSite}
                        changeDate={this.changeDate}
                        changeNoOfPatients={this.changeNoOfPatients}
                        changeAmount={this.changeAmount}
                        deleteRow={this.deleteRow}
                      />
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </div>
            <div className="col-md-10 "></div>
            <div className="col-md-2 ">
              {" "}
              <p className="add-new-payment" onClick={this.addNewRow}>
                {" "}
                + Add New Line{" "}
              </p>
            </div>
            <div className="col-md-10 "></div>
            <div className="col-md-2  ">
              <button
                className="btn btn-success submit-btn"
                onClick={this.submit}
              >
                Submit
              </button>
            </div>
          </div>
        </BaseComponent>
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CombinedCasesComponent);

// export default CombinedCasesComponent;
