import axios from "axios";
import { baseUrl } from "./BaseUrl";

class DeviceApi {
  static devices() {
    return axios
      .get(`${baseUrl}/devices`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  static device(deviceId) {
    return axios
      .get(`${baseUrl}/devices/${deviceId}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  static deviceCases(deviceId) {
    return axios
      .get(`${baseUrl}/devices/${deviceId}/cases`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  static deviceLatestCasesInfo(deviceId) {
    return axios
      .get(`${baseUrl}/devices/${deviceId}/cases/latest`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  static sendNotification(firebaseToken, action, caseId = null) {
    return axios
      .post(`${baseUrl}/notifications/${action}`, {
        firebaseToken,
        caseId
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }
}

export default DeviceApi;
