import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
// Components
import BaseComponent from "../BaseComponent";
import DatatTableComponent from "../Partials/_DatatableComponet";

// Actions
import * as deviceActions from "../../Store/Actions/deviceActions";

function mapStateToProps(store) {
  return {
    devicesStore: store.devices,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deviceActions: bindActionCreators(deviceActions, dispatch),
  };
}

const datatableComponent = [
  {
    label: "Device Id",
    field: "deviceId",
    sort: "asc",
    width: 150,
  },
  {
    label: "Device Name",
    field: "deviceName",
    sort: "asc",
    width: 150,
  },
  {
    label: "Clarious  Version",
    field: "clariusVersion",
    sort: "asc",
    width: 150,
  },
  {
    label: "OS ersion",
    field: "osVersion",
    sort: "asc",
    width: 150,
  },
  {
    label: "PD version",
    field: "pdVersion",
    sort: "asc",
    width: 150,
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 150,
  },
];

const DatatableActions = (props) => (
  <>
    <Link to={`/devices/${props.deviceId}`}>
      <MDBBtn color="purple" size="sm">{`View `}</MDBBtn>
    </Link>{" "}
    <Link to={`/devices/${props.deviceId}/cases`}>
      <MDBBtn color="purple" size="sm">{`Cases `}</MDBBtn>
    </Link>
  </>
);

class ListComponent extends React.Component {
  componentDidMount() {
    this.props.deviceActions.getDevices();
  }
  render() {
    const rows = this.props.devicesStore.devices.map((device) => {
      return {
        deviceId: device.deviceId,
        deviceName: device.deviceName,
        clariusVersion: device.clariusVersion,
        osVersion: device.osVersion,
        pdVersion: device.pdVersion,
        actions: <DatatableActions deviceId={device.deviceId} />,
      };
    });
    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h3 className="panel-title"> Devices</h3>
          </header>
          <DatatTableComponent columns={datatableComponent} rows={rows} />
        </BaseComponent>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListComponent);

// export default ListComponent;
