import React from "react";
import ContentLoader from "react-content-loader";
import BaseComponent from "../BaseComponent";

const DeviceLoaderComponent = () => (
	<BaseComponent>
		<ContentLoader
			height={300}
			width={500}
			speed={2}
			primaryColor="#efefef"
			secondaryColor="#dad9d9"
		>
			<rect x="17" y="17" rx="0" ry="0" width="108" height="141" />
			<rect x="150" y="18" rx="0" ry="0" width="252" height="96" />
			<rect x="412" y="19" rx="0" ry="0" width="78" height="93" />
			<rect x="20" y="178" rx="0" ry="0" width="464" height="70" />
			<rect x="15" y="269" rx="0" ry="0" width="217" height="129" />
			<rect x="250" y="271" rx="0" ry="0" width="235" height="127" />
		</ContentLoader>
	</BaseComponent>
);

export default DeviceLoaderComponent;
