import React from "react";
import { Link } from "react-router-dom";
import store2 from "store2";
import axios from "axios";
import { baseUrl } from "../../Store/Apis/BaseUrl";
// Components
import ErrorComponent from "../Partials/_ErrorComponent";
console.log(baseUrl)

class LoginComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
      loginCode: null,
      showLoginCode: false,
      errMsg: "",
      form: {
        email: "",
        password: "",
      },
    };
  }

  componentDidMount() {
    document.body.className = "animsition page-login-v2 layout-full page-dark";
    document.title = "Oncoustics | Login";
  }

  handleInputChange = (event) => {
    const form = { ...this.state.form };
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  login = (e) => {
    e.preventDefault();
    const { email, password } = this.state.form;
    axios
      .post(`${baseUrl}/auth/login`, { email, password })
      .then((response) => {
        let { success, message, data } = response.data;
        console.log("Login Code", data.loginCode);

        if (success) {
          if (data.type === "site") {
            this.setState(
              {
                hasError: false,
                user: data,
                loginCode: data.loginCode,
                token: response.data._token,
                type: data.type,
              },
              () => {
                this.setTokenAndRedirect();
              }
            );
          }
          this.setState({
            hasError: false,
            showLoginCode: true,
            user: data,
            loginCode: data.loginCode,
            token: response.data._token,
            type: data.type,
          });
        } else {
          this.setState({
            hasError: true,
            errMsg: [message],
          });
        }
      })
      .catch((err) => {
        this.setState({
          hasError: true,
        });
      });
    console.log("Heey");
  };

  enterLoignCode = (e) => {
    e.preventDefault();
    const { loginCode } = this.state.form;
    store2.set("user", this.state.user);
    if (loginCode === this.state.loginCode) {
      this.setTokenAndRedirect();
    } else {
      this.setState({
        hasError: true,
        errMsg: ["Login Code Incorrect"],
      });
    }
  };

  setTokenAndRedirect = () => {
    store2.set("token", this.state.token);
    store2.set("type", this.state.type);
    store2.set("user", this.state.user);

    if (this.state.type === "site") {
      this.props.history.push("/site");
      window.location.reload();
    } else {
      this.props.history.push("/");
      window.location.reload();
    }
  };

  render() {
    return (
      <div className="page">
        <div className="page-content">
          <div className="page-brand-info">
            {/* <div className="brand">
              <img className="brand-img" src="logo.png" alt="..." />
              <h2 className="brand-text font-size-40">Oncoustics</h2>
            </div>
            <p className="font-size-20">
              Oncoustics is dedicated to improving productivity in clinical
              workflow and making ultrasound more widely available, accessible
              to non-experts and affordable for diagnostic imaging.
            </p> */}
          </div>
          <div className="page-login-main">
            <div className="auth-header ">
              <img className="auth-logo" src="/logo_full.png" alt="..." />
              {/* <h3 className="brand-text font-size-40">Oncoustics</h3> */}
            </div>
            <h3 className="auth-text">Sign In</h3>
            <p>Log in to your admin account</p>
            {this.state.hasError && (
              <ErrorComponent errors={this.state.errMsg} />
            )}

            {!this.state.showLoginCode && (
              <>
                <form onSubmit={this.login}>
                  <div
                    className="form-group form-material floating auth-form-field"
                    data-plugin="formMaterial"
                  >
                    <input
                      type="email"
                      className="form-control empty"
                      id="inputEmail"
                      name="email"
                      onChange={this.handleInputChange}
                    />
                    <label className="floating-label" htmlFor="inputEmail">
                      Email
                    </label>
                  </div>
                  <div
                    className="form-group form-material floating auth-form-field"
                    data-plugin="formMaterial"
                  >
                    <input
                      type="password"
                      className="form-control empty"
                      id="inputPassword"
                      name="password"
                      onChange={this.handleInputChange}
                    />
                    <label className="floating-label" htmlFor="inputPassword">
                      Password
                    </label>
                  </div>
                  <button type="submit" className="btn  btn-onc btn-login">
                    Sign in
                  </button>
                </form>
                <p className="auth-helper">
                  Dont have an account?
                  <Link to="/register"> Register</Link>
                </p>
                <p className="auth-helper">
                  Forgot Password
                  <Link to="/forget-password"> Forget</Link>
                </p>
              </>
            )}
            {this.state.showLoginCode && (
              <form onSubmit={this.enterLoignCode}>
                <div
                  className="form-group form-material floating"
                  data-plugin="formMaterial"
                >
                  <input
                    type="text"
                    className="form-control empty auth-form-field"
                    id="inputEmail"
                    name="loginCode"
                    onChange={this.handleInputChange}
                  />
                  <label className="floating-label" htmlFor="inputEmail">
                    Login Code
                  </label>
                </div>
                <button type="submit" className="btn   btn-onc btn-login">
                  Continue
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LoginComponent;
