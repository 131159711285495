import React from "react";

const getDeviceNameFromEmail = (email) => {
  let name = email.substring(0, email.lastIndexOf("@oncoustics.com"));
  return name;
};
const ProfileUserInfoComponent = (props) => {
  let { device, form, handleInputChange, inputDisabled } = props;
  return (
    <div className="col-lg-6 col-md-6 col-sm-12">
      <div className="form-group">
        <div className="input-options">
          <h4 htmlFor="firstName"> {getDeviceNameFromEmail(device.email)}</h4>
        </div>
        {inputDisabled ? (
          <p className="transeduce-name">
            {device.firstName ? (
              <>
                {device.firstName} {device.lastName}
              </>
            ) : (
              <>No Name Added </>
            )}
          </p>
        ) : (
          <>
            <input
              className={`form-control  view-input`}
              onChange={handleInputChange}
              placeholder="First Name"
              name={`${device._id}_firstName`}
              type="text"
              defaultValue={device.firstName}
            />
            <input
              className={`form-control  view-input`}
              onChange={handleInputChange}
              placeholder="Last Name"
              name={`${device._id}_lastName`}
              type="text"
              defaultValue={device.lastName}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileUserInfoComponent;
