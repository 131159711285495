import * as actionTypes from "./actionTypes";
import clariusScrapperApi from "../Apis/clariusScrapperApi";

/**************************** Devices ******************************/
export function getCases() {
  return function(dispatch) {
    dispatch(handle_fetching_cases());
    clariusScrapperApi
      .cases()
      .then(res => {
        if (res.success === true) {
          dispatch(handle_getting_cases_success(res.data, res.lastSyncDate));
        } else {
          dispatch(handle_getting_cases_error(res.data));
        }
      })
      .catch(err => {});
  };
}

function handle_fetching_cases() {
  return {
    type: actionTypes.FETCHING_CASES
  };
}

function handle_getting_cases_success(cases, lastSyncDate) {
  return {
    type: actionTypes.GET_CLARIUS_CASES_SUCCESS,
    cases,
    lastSyncDate: lastSyncDate[0]
  };
}

function handle_getting_cases_error(err) {
  return {
    type: actionTypes.GET_CLARIUS_CASES_ERROR,
    err
  };
}

/**************************** Sync ******************************/
export function sync() {
  return function(dispatch) {
    clariusScrapperApi
      .sync()
      .then(res => {
        if (res.success === true) {
        } else {
        }
      })
      .catch(err => {});
  };
}
