import React from "react";
import { MDBDataTable } from "mdbreact";

const DatatTableComponent = (props) => {
  const { columns, rows, entries } = props;
  const data = {
    columns,
    rows,
  };
  return (
    <MDBDataTable
      // scrollX
      // scrollY
      // maxHeight="400px"
      striped
      bordered
      hover
      data={data}
      entries={entries || 10}
    />
  );
};

export default DatatTableComponent;
