import React from "react";
import ContentLoader from "react-content-loader";

const _DeviceLatestCasesLoader = () => (
  <ContentLoader
    height={300}
    width={500}
    speed={2}
    primaryColor="#efefef"
    secondaryColor="#dad9d9"
  >
    <rect x="412" y="19" rx="0" ry="0" width="78" height="93" />
    <rect x="20" y="178" rx="0" ry="0" width="464" height="70" />
  </ContentLoader>
);

export default _DeviceLatestCasesLoader;
