import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
import moment from "moment";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import { DatePicker, Spin } from "antd";
import axios from "axios";
import { baseUrl } from "../../Store/Apis/BaseUrl";
import  XlsxPopulate  from "xlsx-populate";

// Components
import BaseComponent from "../BaseComponent";
import DatatTableComponent from "../Partials/_DatatableComponet";

// Actions
import * as caseActions from "../../Store/Actions/caseActions";

import { dateFormat, checkDate } from "../../Helpers/dateHelper";
const { RangePicker } = DatePicker;

function mapStateToProps(store) {
  return {
    cases: store.cases,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    caseActions: bindActionCreators(caseActions, dispatch),
  };
}

let newDatatableColumns = [
  {
    label: "Case Id",
    field: "caseId",
    sort: "asc",
    width: 150,
  },
  {
    label: "Country",
    field: "country",
    sort: "asc",
    width: 150,
  },
  {
    label: "liver diseases",
    field: "liver_diseases",
    sort: "asc",
    width: 150,
  },
  {
    label: "existance",
    field: "existance",
    sort: "asc",
    width: 150,
  },
  {
    label: "date",
    field: "date",
    sort: "asc",
    width: 150,
  },
];

const columnsToHide = [
  "_id",
  "patientInfo",
  "caseLink",
  "createdAt",
  "__v",
  "deviceId",
  "caseSourceType",
  "caseFiles",
  "filePath",
  "fileSize",
  "filename",
  "isUploaded",
  "tarFilesYmlInfo",
  "comments",
  "paid",
  // "history[]",
];

const DatatableActions = (props) => {
  let { caseLink, caseId } = props;
  let link = "";
  if (caseLink) {
    link = caseLink
      .split("= '")
      .pop()
      .split("';")[0];
  }

  return (
    <>
      <a href={`https://cloud.clarius.com${link}`}>
        <MDBBtn color="purple" size="sm">{`Clarius view`}</MDBBtn>
      </a>
      <Link to={`/cases/${caseId}`}>
        <MDBBtn color="purple" size="sm">{`Dashboard view `}</MDBBtn>
      </Link>
    </>
  );
};

const PaymentField = (props) => {
  let { paid, caseId } = props;
  console.log("PAYMENT::", caseId, paid, paid == true ? "isPaid" : "NotPaid");
  return (
    <>
      <p>
        It's {/* because FUCK JS */}
        {paid == true ? "Paid" : "Not Paid"}
      </p>
    </>
  );
};
const ChangePaymentField = (props) => {
  let { caseId, editRowPaid } = props;
  const handleChange = (e) => {
    editRowPaid(caseId, e.target.value);
  };
  return (
    <>
      <select onChange={handleChange}>
        <option value={true} selected disabled>
          choose
        </option>
        <option value={true}>paid</option>
        <option value={false}>not paid</option>
      </select>
    </>
  );
};

const CommentsField = (props) => {
  let { comments } = props;
  return (
    <>
      <p>{comments || ""}</p>
    </>
  );
};
const ChangeCommentsField = (props) => {
  let { caseId, comments, editRowComments } = props;
  const handleChange = (e) => {
    editRowComments(caseId, e.target.value);
  };
  return (
    <>
      <textarea rows="4" defaultValue={comments} onChange={handleChange} />
    </>
  );
};

const EditCaseBtn = (props) => {
  let { caseId, handleOpenRowEditing } = props;

  return (
    <>
      <Link to="#" onClick={() => handleOpenRowEditing(caseId)}>
        <MDBBtn color="purple" size="sm">{`Edit`}</MDBBtn>
      </Link>
    </>
  );
};
const SaveCaseBtn = (props) => {
  let { caseId, saveRowInBackend } = props;
  const save = () => {
    console.log("closing..", caseId);
    saveRowInBackend(caseId);
  };
  return (
    <>
      <Link to="#">
        <MDBBtn color="blue" size="sm" onClick={save}>{`Save`}</MDBBtn>
      </Link>
    </>
  );
};

class CombinedCasesComponent extends React.Component {
  state = {
    syncing: false,
    cases: [],
    caseIdBeingUpdated: null,
    loading: true,
    editedRowsValues: {}, // {PDG23123:{paid:true,comments:"das"} }
  };
  age;
  country;
  firstDate;
  secondDate;

  componentDidMount() {
    // this.props.caseActions.getCombinedCases();
    const fetchData = async () => {
      const res = await axios.get(`${baseUrl}/cases/combined`);
      const { success, data } = res.data;
      if (success) {
        this.setState({
          loading: false,
        });
        console.log("Combined Cases:", data);
        this.prepareCasesDataToFitDynamicTable(data);
      } else {
        console.log("Error Getting Payment categories");
      }
    };
    fetchData();
  }

  prepareCasesDataToFitDynamicTable = (cases) => {
    let combined_cases = cases;
    // to prevent duplicates as it cause a weird issue !!
    let fieldNamesArray = [
      "caseId",
      "country",
      "liver_diseases",
      "existance",
      "date",
    ];
    let singleCase;
    let field;
    for (singleCase of combined_cases) {
      for (field in singleCase) {
        if (
          !columnsToHide.includes(field) &&
          !fieldNamesArray.includes(field)
        ) {
          newDatatableColumns.push({
            label: field.replace("_", " "),
            field: field,
            sort: "asc",
            width: 150,
          });
          fieldNamesArray.push(field);
        }
      }
    }
    newDatatableColumns = newDatatableColumns.filter((col, index, self) => {
      return index === self.findIndex((t) => t.field === col.field);
    });
    newDatatableColumns.push({
      label: "Comments",
      field: "comments",
      sort: "asc",
      width: 150,
    });
    newDatatableColumns.push({
      label: "paid",
      field: "paid",
      sort: "asc",
      width: 150,
    });

    newDatatableColumns.push({
      label: "Actions",
      field: "actions",
      sort: "asc",
      width: 150,
    });
    newDatatableColumns.push({
      label: "Edit",
      field: "edit",
      sort: "asc",
      width: 150,
    });

    console.log("fields::", newDatatableColumns);

    this.setState({ cases: combined_cases });
  };

  exportToExcel = async () => {
    const preparedCases = this.state.cases.map((combinedCase) => {
      const prepareObject = {};
      newDatatableColumns.forEach((col) => {
        let field = col.field;
        if (combinedCase[field]) {
          prepareObject[field] = combinedCase[field];
          if (field === "liver_diseases") {
            prepareObject[field] = combinedCase[field] + " ";
          }
          if (
            field === "history" ||
            field === "history[]" ||
            field === "history_other"
          ) {
          
            let history = "";
            if (combinedCase["history"]) {
              history = combinedCase["history"] + " ";
            }
            if (combinedCase["history[]"]) {
              history = combinedCase["history[]"] + " ";
            }
            if (combinedCase["history_other"]) {
              history = combinedCase["history_other"] + " ";
            }
            prepareObject["history"] = history;
          }

          // if (field === "history_other") {
          //   prepareObject[field] = combinedCase[field] + " ";
          // }
        } else {
          prepareObject[field] = "";
        }
      });
      return prepareObject;
    });
    // var ws = XLSX.utils.json_to_sheet(preparedCases, {
    //   header: newDatatableColumns.map((r) => r.field),
    // });
    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    // await XLSX.writeFile(wb, "Combined Cases.xls");

          const generateExcelFile = async () => {
        const workbook = await XlsxPopulate.fromBlankAsync();
        const headers = newDatatableColumns.map((r) => r.field);
        const sheet = workbook.sheet(0);
        headers.forEach((header, index) => {
          sheet.cell(1, index + 1).value(header);
        });
        preparedCases.forEach((row, rowIndex) => {
          headers.forEach((header, colIndex) => {
            if(row.hasOwnProperty(header)){
              let cellValue = row[header];
              if(Array.isArray(cellValue)){
                cellValue = JSON.stringify(cellValue)
              } 
              sheet.cell(rowIndex + 2, colIndex + 1).value(cellValue);
            } else {
              console.log(`Header '${header}' not found in row:`, row);
            }
          });
        });
        const fileBuffer = await workbook.outputAsync();
        const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'Combined Cases.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };
      generateExcelFile();

  };

  filterByDate = (dates) => {
    if (dates.length === 0) {
      this.firstDate = null;
      this.secondDate = null;
      return;
    }
    this.firstDate = dates[0];
    this.secondDate = dates[1];
  };

  filter = () => {
    if (
      this.age.value.length > 0 &&
      this.country.value.length > 0 &&
      this.firstDate
    ) {
      console.log("Searching  All");

      const filtred_cases = this.props.cases.combined_cases.filter(
        (singleCase) => {
          if (
            singleCase.case_info.length > 0 &&
            singleCase.case_info[0].patientInfo.age ===
              Number(this.age.value) &&
            singleCase.case_info[0].country === this.country.value &&
            checkDate(
              this.firstDate,
              this.secondDate,
              dateFormat(
                moment(singleCase.examDate.split("a.m.")[0].split("p.m.")[0])
              )
            )
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases,
      });
      return;
    }
    if (this.age.value.length > 0 && this.country.value.length > 0) {
      console.log("Searching  Country and age");

      const filtred_cases = this.props.cases.combined_cases.filter(
        (singleCase) => {
          if (
            singleCase.case_info.length > 0 &&
            singleCase.case_info[0].patientInfo.age ===
              Number(this.age.value) &&
            singleCase.case_info[0].country === this.country.value
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases,
      });
      return;
    }

    if (this.age.value.length > 0 && this.firstDate) {
      console.log("Searching  age and Date");

      const filtred_cases = this.props.cases.combined_cases.filter(
        (singleCase) => {
          if (
            singleCase.case_info.length > 0 &&
            singleCase.case_info[0].patientInfo.age ===
              Number(this.age.value) &&
            checkDate(
              this.firstDate,
              this.secondDate,
              dateFormat(
                moment(singleCase.examDate.split("a.m.")[0].split("p.m.")[0])
              )
            )
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases,
      });
      return;
    }

    if (this.country.value.length > 0 && this.firstDate) {
      console.log("Searching  Country and Date");

      const filtred_cases = this.props.cases.combined_cases.filter(
        (singleCase) => {
          if (
            singleCase.case_info.length > 0 &&
            singleCase.case_info[0].country === this.country.value &&
            checkDate(
              this.firstDate,
              this.secondDate,
              dateFormat(
                moment(singleCase.examDate.split("a.m.")[0].split("p.m.")[0])
              )
            )
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases,
      });
      return;
    }

    if (this.country.value.length > 0) {
      console.log("Searching Countr Only");

      const filtred_cases = this.props.cases.combined_cases.filter(
        (singleCase) => {
          if (
            singleCase.case_info.length > 0 &&
            singleCase.case_info[0].country === this.country.value
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases,
      });
      return;
    }

    if (this.age.value.length > 0) {
      console.log("Searching Age Only");
      const filtred_cases = this.props.cases.combined_cases.filter(
        (singleCase) => {
          if (
            singleCase.case_info.length > 0 &&
            singleCase.case_info[0].patientInfo.age === Number(this.age.value)
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases,
      });
      return;
    }
  };

  editRowComments = (caseId, comments) => {
    let values = this.state.editedRowsValues;
    values[caseId] = {
      ...values[caseId],
      comments: comments,
    };
    this.setState({
      editedRowsValues: values,
    });
  };

  editRowPaid = (caseId, paid) => {
    let values = this.state.editedRowsValues;
    values[caseId] = {
      ...values[caseId],
      paid: paid,
    };
    this.setState({
      editedRowsValues: values,
    });
  };

  saveRowInBackend = async (caseId) => {
    let values = this.state.editedRowsValues;
    let rowValues = values[caseId];
    if (!rowValues) {
      this.handleCloseRowEditing(caseId, null, null);
      return;
    }
    console.log("Going to save thes Values :  ", rowValues);
    const res = await axios.post(`${baseUrl}/cases/${caseId}/info`, rowValues);
    const { success, data } = res.data;
    if (success) {
      console.log("PAYMENT Saved Row In Backend :", caseId, rowValues);
      this.handleCloseRowEditing(caseId, rowValues.comments, rowValues.paid);
    } else {
      console.log("Error saving row");
    }
  };
  handleOpenRowEditing = (caseId) => {
    console.log("case Id", caseId);

    let { cases } = this.state;
    let caseToUpdate = null;
    let new_cases = cases.map((singleCase) => {
      if (singleCase.caseId === caseId) {
        singleCase.updateRowStatus = "update";
        caseToUpdate = singleCase;
      }
      return singleCase;
    });
    let sorted_cases = [];
    sorted_cases.push(caseToUpdate);
    new_cases.forEach((singleCase) => {
      // to remove duplicate
      if (singleCase.caseId !== caseId) sorted_cases.push(singleCase);
    });

    // console.log("SORTED CASS", caseToUpdate, sorted_cases);
    this.setState({
      cases: sorted_cases,
    });
  };

  handleCloseRowEditing = (caseId, comments, paid) => {
    console.log("closing case Id", caseId);

    let { cases } = this.state;
    let caseToUpdate = null;
    let new_cases = cases.map((singleCase) => {
      if (singleCase.caseId === caseId) {
        singleCase.updateRowStatus = "";
        if (comments !== null) {
          singleCase.comments = comments;
        }
        if (paid !== null) {
          console.log("PAYMENT:", paid);
          singleCase.paid = paid;
        }

        caseToUpdate = singleCase;
      }
      return singleCase;
    });
    let sorted_cases = [];
    sorted_cases.push(caseToUpdate);
    new_cases.forEach((singleCase) => {
      // to remove duplicate
      if (singleCase.caseId !== caseId) sorted_cases.push(singleCase);
    });

    // console.log("SORTED CASS", caseToUpdate, sorted_cases);
    this.setState({
      cases: sorted_cases,
    });
  };

  sort = (input) => {
    const sortType = input.target.value;
    if (sortType === "sort") {
      this.setState({
        cases: this.props.cases.combined_cases,
      });
      return;
    }
    const filtred_cases = this.props.cases.combined_cases.sort((a, b) => {
      let value =
        new Date(moment(b.examDate.split("a.m.")[0].split("p.m.")[0])) -
        new Date(moment(a.examDate.split("a.m.")[0].split("p.m.")[0]));
      if (sortType === "date-dsc") {
        return value;
      }
      return value * -1;
    });

    this.setState({
      cases: filtred_cases,
    });
  };

  clear = () => {
    this.age.value = "";
    this.country.value = "";
    this.setState({
      cases: this.props.cases.combined_cases,
    });
  };

  render() {
    let rows = [];
    rows = this.state.cases.map((combinedCase) => {
      const prepareObject = {};
      newDatatableColumns.forEach((col) => {
        let field = col.field;
        if (combinedCase[field]) {
          prepareObject[field] = combinedCase[field];
        } else {
          prepareObject[field] = "";
        }
      });
      if (combinedCase.updateRowStatus === "update") {
        prepareObject.comments = (
          <ChangeCommentsField
            caseId={combinedCase.caseId}
            comments={combinedCase.comments}
            editRowComments={this.editRowComments}
          />
        );
        prepareObject.paid = (
          <ChangePaymentField
            caseId={combinedCase.caseId}
            paid={combinedCase.paid}
            editRowPaid={this.editRowPaid}
          />
        );
        prepareObject.edit = (
          <SaveCaseBtn
            caseId={combinedCase.caseId}
            saveRowInBackend={this.saveRowInBackend}
          />
        );
      } else {
        prepareObject.comments = (
          <CommentsField comments={combinedCase.comments} />
        );
        prepareObject.paid = (
          <PaymentField caseId={combinedCase.caseId} paid={combinedCase.paid} />
        );
        prepareObject.edit = (
          <EditCaseBtn
            caseId={combinedCase.caseId}
            handleOpenRowEditing={this.handleOpenRowEditing}
          />
        );
      }

      prepareObject.actions = (
        <DatatableActions
          caseLink={combinedCase.caseLink}
          caseId={combinedCase.caseId}
        />
      );

      return prepareObject;
    });

    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h3 className="panel-title"> Combined Cases</h3>
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-success export-btn"
                  onClick={this.exportToExcel}
                >
                  Export to Excel
                </button>
                <RangePicker allowClear onChange={this.filterByDate} />
              </div>
              <div className="col-md-12 filter-tab">
                <div className="row">
                  <div className="col-md-3">
                    <input
                      type="number"
                      ref={(el) => (this.age = el)}
                      placeholder="Filter by Age"
                      className="form-control "
                    />
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      ref={(el) => (this.country = el)}
                      placeholder="Filter by country"
                      className="form-control "
                    />
                  </div>
                  <div className="col-md-3">
                    <button
                      className="btn btn-primary clear"
                      onClick={this.filter}
                    >
                      Filter
                    </button>
                    <button className="btn btn-danger " onClick={this.clear}>
                      Clear
                    </button>
                  </div>
                  <div className="col-md-3">
                    <select onChange={this.sort}>
                      <option value="sort">sort By</option>
                      <option value="date-asc">Date (ASC)</option>
                      <option value="date-dsc">Date (DSC)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {this.state.loading ? (
            <Spin />
          ) : (
            <DatatTableComponent
              columns={newDatatableColumns}
              rows={rows}
              entries={100}
            />
          )}
        </BaseComponent>
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CombinedCasesComponent);

// export default CombinedCasesComponent;
