import React from "react";
import CanvasDraw from "react-canvas-draw";
import { Player } from "video-react";

// Components
import BaseComponent from "../../BaseComponent";

class TestComponent extends React.Component {
  saveImageCords = () => {
    localStorage.setItem("savedDrawing", this.canvasRef.getSaveData());
    console.log("Image Cord", this.canvasRef.getSaveData());
  };
  clearImageCords = () => {
    localStorage.setItem("savedDrawing", null);
    this.canvasRef.clear();
  };

  render() {
    let video_url =
      "https://backend-upload-test.s3.us-east-2.amazonaws.com/PDG1567469819473/%7B0d3da9bb-6475-45a4-b51c-8789031240c9%7D.avi-iULy-1567500950.mp4";
    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h3 className="panel-title"> Cases</h3>
          </header>
          <button onClick={this.saveImageCords}>Save Data</button>
          <button onClick={this.clearImageCords}>Clear Data</button>

          <embed src={video_url} />

          {/* <object height="350" width="425">
            <param name="movie" value={video_url} />
            <param name="wmode" value="transparent" />
            <embed
              height="350"
              src={video_url}
              rel="0"
              type="application/x-shockwave-flash"
              width="425"
              wmode="transparent"
            />
          </object> */}

          <Player playsInline poster="/assets/poster.png" src={video_url} />
          <CanvasDraw
            ref={canvasDraw => (this.canvasRef = canvasDraw)}
            brushColor="white"
            imgSrc="cases-images/case-1.jpg"
            brushRadius={1}
            saveData={localStorage.getItem("savedDrawing")}
            // canvasWidth={700}
            // canvasHeight={500}
          />
        </BaseComponent>
      </>
    );
  }
}

export default TestComponent;
