import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Components
import FooterComponent from "../Partials/_FooterComponent";
import HeaderComponent from "../Partials/_HeaderComponent";
import SidebarComponent from "../Partials/_SidebarComponent";
import HomepageLoader from "../Loaders/_HomepageLoader";
// Actions
// import * as deviceActions from "../Store/Actions/deviceActions";
// import * as caseActions from "../Store/Actions/caseActions";

function mapStateToProps(store) {
  return {
    devicesStore: store.devices,
    casesStore: store.cases,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // deviceActions: bindActionCreators(deviceActions, dispatch),
    // caseActions: bindActionCreators(caseActions, dispatch),
  };
}

class HomepageComponent extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {}

  render() {
    if (this.state.loading) {
      return <HomepageLoader />;
    }

    return (
      <>
        <HeaderComponent />
        <SidebarComponent />
        <div className="page">
          <div className="page-content container-fluid">
            <div className="row">
              <div class="col-xl-3 col-md-6">
                <div class="card card-shadow" id="widgetLineareaTwo">
                  <div class="card-block p-20 p-t-10">
                    <div class="clearfix">
                      <div class="grey-800 pull-xs-left p-y-10">
                        <i class="icon md-flash grey-600 font-size-24 vertical-align-bottom m-r-5"></i>
                        Devices
                      </div>
                      <span class="pull-xs-right grey-700 font-size-30">-</span>
                    </div>
                    <div class="m-b-20 grey-500"></div>
                    <div class="ct-chart h-50"></div>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-md-6">
                <div class="card card-shadow" id="widgetLineareaTwo">
                  <div class="card-block p-20 p-t-10">
                    <div class="clearfix">
                      <div class="grey-800 pull-xs-left p-y-10">
                        <i class="icon md-account grey-600 font-size-24 vertical-align-bottom m-r-5"></i>
                        Cases
                      </div>
                      <span class="pull-xs-right grey-700 font-size-30">-</span>
                    </div>
                    <div class="m-b-20 grey-500"></div>
                    <div class="ct-chart h-50"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomepageComponent);
