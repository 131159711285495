import axios from "axios";
import { baseUrl } from "./BaseUrl";

class clariusScrapperApi {
  static cases() {
    return axios
      .get(`${baseUrl}/clarius/cases`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  static sync() {
    return axios
      .get(`${baseUrl}/clarius/sync`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }
}

export default clariusScrapperApi;
