import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Progress } from "antd"
import { Bar } from "react-chartjs-2"
import axios from "axios"
import { baseUrl } from "../../../Store/Apis/BaseUrl"
import { DatePicker, Spin } from "antd"
import Select from "react-select"
import Pdf from "react-to-pdf"

// Components
import FooterComponent from "../../../Components/Partials/_FooterComponent"
import HeaderComponent from "../../../Components/Partials/_HeaderComponent"
import SidebarComponent from "../../../Components/Partials/_SidebarComponent"
import HomepageLoader from "../../../Components/Loaders/_HomepageLoader"
import ReportsComponent from "../../../Components/Reports"
// Actions
import * as deviceActions from "../../../Store/Actions/deviceActions"
import * as caseActions from "../../../Store/Actions/caseActions"

const { RangePicker } = DatePicker
// const sitesOptions = [
//   { value: "all", label: "View all" },
//   // { value: "China", label: "China" },
//   // { value: "Egypt", label: "Egypt" },
//   // { value: "Canada", label: "Canada" },
//   // { value: "Korea", label: "Korea" },
//   // { value: "USA", label: "USA" },
// ];

function mapStateToProps(store) {
  return {
    devicesStore: store.devices,
    casesStore: store.cases,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deviceActions: bindActionCreators(deviceActions, dispatch),
    caseActions: bindActionCreators(caseActions, dispatch),
  }
}

const reportToPDFRef = React.createRef()
/**
 * 1- diseases
 * 2- usabale
 * 3- unusable
 */
class HomepageComponent extends React.Component {
  state = {
    selectedCountry: "all",
    dataBar: {},
    stats: {},
    loading: true,
    comments_count: {},
    totalPatients: 0,
    usable: 0,
    unusable: 0,
    pending: 0,
    paid: 0,
    totalDataQualityChart: [],
    dataQualityChart: [],
    paidUsableCasesChart: [],
    diseasesGraph: [],
    selectedSite: "all",
    sitesOptions: [{ value: "all", label: "View all" }],
    paidCasesCount: 0,
  }
  startDate
  endDate
  componentDidMount() {
    // this.props.deviceActions.getDevices();
    // this.props.caseActions.getCases();
    this.getSites()
    this.getReports()
    this.getPaidCasesCount()
  }
  getReports = async () => {
    let url = `${baseUrl}/cases/stats?site=${this.state.selectedSite}`
    if (this.startDate && this.endDate) {
      url += `&startDate=${this.startDate}&endDate=${this.endDate}`
    }
    const res = await axios.get(url)

    const { success, data } = res.data

    if (success) {
      let dataBar = {}
      let labels = []
      let bar_data = []
      data.yearlyGraph.map((g) => {
        labels.push(g._id)
        bar_data.push(g.count)
      })
      dataBar.labels = labels
      dataBar.datasets = [
        {
          label: "Patients Scaned Per Month",
          data: bar_data,
          // backgroundColor: DataBarbackgroundColor,
          backgroundColor: "rgba(21,71,106)",
        },
      ]
      console.log("dataBar:", dataBar)
      let totalDataQualityChart = [
        {
          id: "usable",
          label: "usable",
          value: data.totalUsable ? data.totalUsable : 0,
        },
        {
          id: "unusable",
          label: "unusable",
          value: data.totalUnusable ? data.totalUnusable : 0,
        },
        {
          id: "pending",
          label: "Pending",
          value: data.totalPending ? data.totalPending : 0,
        },
      ]
      let dataQualityChart = [
        {
          id: "usable",
          label: "usable",
          value: data.ussable ? data.ussable : 0,
        },
        {
          id: "unusable",
          label: "unusable",
          value: data.unusable ? data.unusable : 0,
        },
        {
          id: "pending",
          label: "Pending",
          value: data.uending ? data.uending : 0,
        },
      ]
      let paidUsableCasesChart = [
        {
          id: "usable",
          label: "usable",
          value: data.totalUsable - data.totalPaid,
        },
        {
          id: "paid",
          label: "paid",
          value: data.totalUsable,
        },
      ]
      this.setState({
        loading: false,
        stats: data,
        comments_count: data.comments_count,
        totalPatients: data.totalPatients,
        totalUsable: data.totalUsable,
        totalUnusable: data.totalUnusable,
        totalPending: data.totalPending,
        totalPaid: data.totalPaid,
        totalDataQualityChart,

        patients: data.patients,
        usable: data.usable,
        unusable: data.unusable,
        pending: data.pending,
        paid: data.paid,
        dataBar,
        dataQualityChart,
        paidUsableCasesChart,
        diseasesGraph: data.diseasesGraph,
      })
      console.log("Cases stats:", data)
    } else {
    }
  }

  getSites = async () => {
    let url = `${baseUrl}/sites`
    const res = await axios.get(url)

    const { success, sites } = res.data
    let newSites = []
    if (success) {
      newSites = sites.map((site) => {
        return { value: site.id, label: site.title }
      })
    }
    newSites.push({ value: "all", label: "View all" })
    this.setState({ sitesOptions: newSites })
    console.log("SITES ARER:", newSites)
  }

  getPaidCasesCount = async () => {
    let url = `${baseUrl}/payments/paid_cases?siteId=${this.state.selectedSite}`
    const res = await axios.get(url)

    const { success, sites } = res.data
    let paidCasesCount = 0
    if (success) {
      paidCasesCount = res.data.paidCasesCount
    }

    this.setState({ paidCasesCount })
    console.log("paidCasesCount:", paidCasesCount)
  }
  changeSite = (input) => {
    console.log("input", input.value)
    this.setState(
      {
        loading: true,
        selectedCountry: input.label,
        selectedSite: input.value,
      },
      () => {
        this.getReports()
        this.getPaidCasesCount()
      }
    )
  }

  exportToPdf = () => {}

  filterByDate = (dates) => {
    if (dates.length === 0) {
      this.startDate = null
      this.endDate = null
      this.getReports()
      return
    }
    this.startDate = dates[0].format("YYYY-M-D")
    this.endDate = dates[1].format("YYYY-M-D")

    console.log("Dates os:", dates, this.startDate, this.endDate)
    this.getReports()
  }
  render() {
    // const { loading: deviceLoading } = this.props.devicesStore;
    // const { loading: caseLoading } = this.props.casesStore;

    if (this.state.loading) {
      return <HomepageLoader />
    }

    let {
      selectedSite,
      selectedCountry,
      totalPatients,
      totalUsable,
      totalUnusable,
      totalPending,
      totalPaid,
      totalDataQualityChart,

      patients,
      usable,
      unusable,
      pending,
      paid,
      dataQualityChart,

      // paidUsableCasesChart,
      diseasesGraph,
      comments_count,
      dataBar,
      paidCasesCount,
    } = this.state

    let paidUsableCasesChart = [
      {
        id: "usable",
        label: "usable",
        value: totalUsable - paidCasesCount,
      },
      {
        id: "paid",
        label: "paid",
        value: paidCasesCount,
      },
    ]

    console.log(
      totalPatients,
      totalUsable,
      totalUnusable,
      totalPending,
      totalPaid,

      usable,
      unusable,
      pending,
      paid,
      patients
    )
    return (
      <>
        <HeaderComponent />
        <SidebarComponent />
        <div className="page">
          <div className="page-content container-fluid">
            <div className="dashboard-content">
              <div className="dashboard-header">
                {/* <h4>China: Clarius 01,Clarius 02,Clarius 03</h4> */}
                <Pdf
                  targetRef={reportToPDFRef}
                  filename="oncoustics-report.pdf">
                  {({ toPdf }) => (
                    <button
                      className="btn btn-success btn-export"
                      onClick={toPdf}>
                      Export PDF
                    </button>
                  )}
                </Pdf>

                <Select
                  options={this.state.sitesOptions}
                  onChange={this.changeSite}
                  classNamePrefix="reportSelect"
                  placeholder="Select Site"
                />
              </div>
              <div ref={reportToPDFRef}>
                <ReportsComponent
                  selectedCountry={selectedCountry}
                  totalPatients={totalPatients}
                  totalUsable={totalUsable}
                  totalUnusable={totalUnusable}
                  totalPending={totalPending}
                  totalPaid={totalPaid}
                  totalDataQualityChart={totalDataQualityChart}
                  patients={patients}
                  usable={usable}
                  unusable={unusable}
                  pending={pending}
                  paid={paid}
                  dataQualityChart={dataQualityChart}
                  paidUsableCasesChart={paidUsableCasesChart}
                  diseasesGraph={diseasesGraph}
                  comments_count={comments_count}
                  dataBar={dataBar}>
                  <div class="col-xl-12 col-md-12">
                    <h1 className="dashboard-site-title">
                      {selectedCountry.charAt(0).toUpperCase() +
                        selectedCountry.slice(1)}
                    </h1>

                    <h2 className="stats-time-seprator">
                      <RangePicker allowClear onChange={this.filterByDate} />
                      {/* Last Week: Sept 14-18 */}
                    </h2>
                  </div>
                </ReportsComponent>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomepageComponent)
