import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./Store/Reducers/reducerWrapper"; //Reducer wrapper

// const middleware = applyMiddleware(thunk,promise(),logger); //for test

const middleware = applyMiddleware(logger, thunk);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeWithDevTools(middleware));

export default store;
