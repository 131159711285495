import React from "react"
import axios from "axios"
import store2 from "store2"
import { baseUrl } from "../../../Store/Apis/BaseUrl"

import { Link } from "react-router-dom"

// Components
import FooterComponent from "../../../Components/Partials/_FooterComponent"
import HeaderComponent from "../../../Components/Partials/_HeaderComponent"
import SidebarComponent from "../../../Components/Partials/_SidebarComponent"
import HomepageLoader from "../../../Components/Loaders/_HomepageLoader"
import ProfileUserInfoComponent from "../../../Components/Profile/_ProfileUserInfoComponent.js"

const USER = store2.get("user")
const JWT_TOKEN = store2.get("token")

class ProgilePage extends React.Component {
  state = {
    loading: true,
    hasError: false,
    errMsg: [],
    inputDisabled: true,
    devices: [1, 3, 3, 3],
    form: {},
  }

  componentDidMount() {
    this.getSiteUsers()
  }

  getSiteUsers = () => {
    axios
      .get(`${baseUrl}/sites/${USER.siteId}`, {
        headers: {
          token: JWT_TOKEN,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          console.log("user data", res.data)
          this.setState({
            loading: false,
            devices: res.data.devices,
          })
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "Server Error ",
          })
        }
      })
      .catch((err) => {
        return err
      })
  }

  handleInputChange = (event) => {
    let form = { ...this.state.form }
    let [deviceId, inputname] = event.target.name.split("_")
    let value = event.target.value
    console.log(form, deviceId, inputname, value)
    if (!form[deviceId]) {
      form[deviceId] = {}
    }
    form[deviceId][inputname] = value
    console.log(form)
    this.setState({ form })
  }

  editProfile = (e) => {
    e.preventDefault()
    this.setState({
      inputDisabled: false,
    })
  }

  updateProfile = (e) => {
    e.preventDefault()
    axios
      .put(
        `${baseUrl}/sites/${USER.siteId}`,
        { devices: this.state.form },
        {
          headers: {
            token: JWT_TOKEN,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          this.getSiteUsers()
          this.setState({
            inputDisabled: true,
          })
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "Server Error ",
          })
        }
      })
      .catch((err) => {
        return err
      })
    // redirect to exam
  }
  getDeviceNameFromEmail = (email) => {
    let name = email.substring(0, email.lastIndexOf("@oncoustics.com"))
    return name
  }

  render() {
    let { loading, hasError, form, inputDisabled, devices } = this.state

    if (this.state.loading) {
      return <HomepageLoader />
    }

    return (
      <>
        <HeaderComponent />
        <SidebarComponent />
        <div className="page">
          <div className="page-content container-fluid">
            <div className="dashboard-content">
              <div className="dashboard-header">
                <h4 className="profile-header">Profile</h4>
                {/* <div> */}
                {inputDisabled ? (
                  <button
                    className="btn btn-success btn-export"
                    onClick={this.editProfile}>
                    Edit Profile
                  </button>
                ) : (
                  <button
                    className="btn btn-success btn-export"
                    onClick={this.updateProfile}>
                    Save
                  </button>
                )}
                {/* </div> */}
              </div>
              <div className="row">
                <div class="col-xl-12 col-md-12">
                  <h2 className="stats-time-seprator">
                    China:
                    {devices.map((device) => {
                      return ` ${this.getDeviceNameFromEmail(device.email)} , `
                    })}
                  </h2>
                </div>
                <div className="col-md-8">
                  {loading && <h1> Loading..</h1>}

                  {!loading && !hasError && (
                    <div className="user-info">
                      <h4 className="transeducers"> Transducers</h4>
                      <form
                        action
                        className="form-data"
                        onSubmit={this.updateProfile}>
                        <div className=" row">
                          {devices.map((device) => {
                            return (
                              <ProfileUserInfoComponent
                                inputDisabled={inputDisabled}
                                form={form}
                                device={device}
                                editProfile={this.editProfile}
                                updateProfile={this.updateProfile}
                                handleInputChange={this.handleInputChange}
                              />
                            )
                          })}
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </>
    )
  }
}
export default ProgilePage
