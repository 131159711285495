import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  cases: [],
  combined_cases: [],
  case: null,
  error_message: [],
  hasError: false,
  loading: false
};

export function caseReducer(state = initialState, action) {
  switch (action.type) {
    //******************* CASES ********************\\
    case actionTypes.FETCHING_CASES:
      return {
        ...state,
        cases: [],
        case: null,
        loading: true,
        hasError: false,
        error_message: []
      };

    case actionTypes.GET_CASES_ERROR:
      return {
        ...state,
        saved: false,
        cases: []
      };

    case actionTypes.GET_CASES_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        empty: false,
        cases: action.cases
      };

    //******************* COMIBNED CASES ********************\\
    case actionTypes.FETCHING_COMBINED_CASES:
      return {
        ...state,
        combined_cases: [],
        case: null,
        loading: true,
        hasError: false,
        error_message: []
      };

    case actionTypes.GET_COMBINED_CASES_ERROR:
      return {
        ...state,
        saved: false,
        cases: []
      };

    case actionTypes.GET_COMBINED_CASES_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        empty: false,
        combined_cases: action.cases
      };

    //******************* Case ********************\\
    case actionTypes.FETCHING_CASE:
      return {
        ...state,
        loading: true,
        hasError: false,
        error_message: []
      };

    case actionTypes.GET_CASE_ERROR:
      return {
        ...state,
        saved: false,
        hasError: true,
        error_message: action.err,
        case: null
      };

    case actionTypes.GET_CASE_SUCCESS:
      return {
        ...state,
        saved: true,
        empty: false,
        case: action.case
      };

    default:
      return { ...state };
  }
}
