import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Carousel } from "antd";
import { Player } from "video-react";

import "../Partials/antd.css";

// Components
import FooterComponent from "../Partials/_FooterComponent";
import HeaderComponent from "../Partials/_HeaderComponent";
import SidebarComponent from "../Partials/_SidebarComponent";
import DeviceLoaderComponent from "../Loaders/_DeviceLoaderComponent";

// Helpers

// Actions
import * as caseActions from "../../Store/Actions/caseActions";

function mapStateToProps(store) {
  return {
    caseStore: store.cases
  };
}

function mapDispatchToProps(dispatch) {
  return {
    caseActions: bindActionCreators(caseActions, dispatch)
  };
}

class SingleCaseComponent extends React.Component {
  componentDidMount() {
    const { caseId } = this.props.match.params;
    this.props.caseActions.getCase(caseId);
    document.body.className = "animsition page-profile-v2 site-menubar-unfold";
  }

  onChange = (a, b, c) => {
    console.log(a, b, c);
  };
  render() {
    const { case: singleCase, error_message } = this.props.caseStore;

    if (!singleCase) {
      return <DeviceLoaderComponent error={error_message} />;
    }
    const { caseData, gcsUrls } = singleCase;
    return (
      <>
        <HeaderComponent />
        <SidebarComponent />
        <div className="page">
          <div className="page-content container-fluid">
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  {/* 1st:  Videos*/}
                  <div className="col-lg-12 col-xl-12  col-xs-12">
                    <div className="card user-visitors">
                      <div className="card-header card-header-transparent p-20">
                        <h4 className="card-title m-b-0">Videos </h4>
                      </div>
                      <div className="card-block">
                        <Carousel value="right" afterChange={this.onChange}>
                          {gcsUrls.videos.map(video => {
                            return (
                              <div>
                                <Player playsInline poster="" src={video} />
                              </div>
                            );
                          })}
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="user-info card card-shadow text-xs-center">
                  <div className="user-base card-block">
                    <br />
                    <h4 className="user-name text-center">{caseData.caseId}</h4>
                    <p className="user-job">{caseData.gender}</p>
                    <p className="user-location">Country:{caseData.country} </p>
                    <p className="user-location">date:22-10-2019 </p>
                    <p className="user-location">
                      Age:{caseData.patientInfo.age}{" "}
                    </p>
                    <p className="user-location">
                      Wight:{caseData.patientInfo.weight}{" "}
                    </p>
                    <p className="user-location">
                      BMI:{caseData.patientInfo.bmi}{" "}
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="row no-space">
                      <div className="col-xs-4">
                        <strong className="profile-stat-count">
                          {gcsUrls.videos.length}
                        </strong>
                        <span>Videos</span>
                      </div>
                      <div className="col-xs-4">
                        <strong className="profile-stat-count">
                          {gcsUrls.tars.length}
                        </strong>
                        <span>Tars</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card user-visitors">
                  <div class="card-header card-header-transparent p-20">
                    <h4 class="card-title m-b-0">Download Tars</h4>
                  </div>
                  <div class="card-block">
                    <ul class="list-group list-group-full">
                      {gcsUrls.tars.map(tar => {
                        return (
                          <li class="list-group-item">
                            <div class="media">
                              <div class="media-body">
                                <h4 class="media-heading hover">
                                  {
                                    tar
                                      .split(`${caseData.caseId}/`)
                                      .pop()
                                      .split("?")[0]
                                  }
                                </h4>
                              </div>
                              <div class="media-right ">
                                <a href={tar}>
                                  <button
                                    class="btn btn-icon btn-primary btn-outline btn-round btn-xs waves-effect"
                                    type="button"
                                    name="button"
                                  >
                                    <i class="icon icon-xs md-cloud-download m-r-0"></i>
                                  </button>
                                </a>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCaseComponent);
