import React from "react";

const ErrorComponent = props => (
	<div className="err-box">
		{props.errors &&
			props.errors.length > 0 &&
			Object.keys(props.errors).map(index => {
				return <li key={`error-${index}`}>{props.errors[index]}</li>;
			})}
	</div>
);
export default ErrorComponent;
