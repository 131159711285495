import React from "react";
import axios from "axios";
import store2 from "store2";
import { baseUrl } from "../../../../Store/Apis/BaseUrl";

import { Link } from "react-router-dom";

// Components
import FooterComponent from "../../../../Components/Partials/_FooterComponent";
import HeaderComponent from "../../../../Components/Partials/_HeaderComponent";
import SidebarComponent from "../../../../Components/Partials/_SidebarComponent";
import HomepageLoader from "../../../../Components/Loaders/_HomepageLoader";
import ProfileUserInfoComponent from "../../../../Components/Profile/_ProfileUserInfoComponent.js";

const USER = store2.get("user");
const JWT_TOKEN = store2.get("token");

class ProgilePage extends React.Component {
  state = {
    loading: false,
    hasError: false,
    errMsg: [],
    inputDisabled: true,
    form: {},
  };

  componentDidMount() {}

  handleInputChange = (event) => {
    const form = { ...this.state.form };
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  createAccount = (e) => {
    e.preventDefault();
    axios
      .post(
        `${baseUrl}/accounts`,
        { account: this.state.form },
        {
          headers: {
            token: JWT_TOKEN,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          window.location.replace("/accounts");
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "Server Error ",
          });
        }
      })
      .catch((err) => {
        // window.location.replace("/accounts");

        return err;
      });
    // redirect to exam
  };

  render() {
    let { loading, hasError } = this.state;

    if (this.state.loading) {
      return <HomepageLoader />;
    }

    return (
      <>
        <HeaderComponent />
        <SidebarComponent />
        <div className="page">
          <div className="page-content container-fluid">
            <div className="dashboard-content">
              <div className="dashboard-header">
                <button
                  className="btn btn-success btn-export"
                  onClick={this.createAccount}
                >
                  Save
                </button>
              </div>
              <div className="row">
                <div className="col-md-8">
                  {loading && <h1> Loading..</h1>}

                  {!loading && !hasError && (
                    <div className="user-info">
                      <h4 className="transeducers"> Accounts</h4>
                      <form
                        action
                        className="form-data"
                        onSubmit={this.createAccount}
                      >
                        <div className=" row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <div className="input-options">
                                <h4 htmlFor="firstName">First Name</h4>
                              </div>
                              <input
                                className={`form-control  view-input`}
                                onChange={this.handleInputChange}
                                placeholder="First Name"
                                type="text"
                                defaultValue=""
                                name="firstName"
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <div className="input-options">
                                <h4 htmlFor="firstName">Last Name</h4>
                              </div>
                              <input
                                className={`form-control  view-input`}
                                onChange={this.handleInputChange}
                                placeholder="Last Name"
                                type="text"
                                defaultValue=""
                                name="lastName"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <div className="input-options">
                                <h4 htmlFor="specialization">Specialization</h4>
                              </div>
                              <input
                                className={`form-control  view-input`}
                                onChange={this.handleInputChange}
                                placeholder="Specialization"
                                type="text"
                                defaultValue=""
                                name="specialization"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <div className="input-options">
                                <h4 htmlFor="institution">Institution</h4>
                              </div>
                              <input
                                className={`form-control  view-input`}
                                onChange={this.handleInputChange}
                                placeholder="Institution"
                                type="text"
                                defaultValue=""
                                name="institution"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <div className="input-options">
                                <h4 htmlFor="email">Email</h4>
                              </div>
                              <input
                                className={`form-control  view-input`}
                                onChange={this.handleInputChange}
                                placeholder="Email"
                                type="email"
                                defaultValue=""
                                name="email"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <div className="input-options">
                                <h4 htmlFor="password">Password</h4>
                              </div>
                              <input
                                className={`form-control  view-input`}
                                onChange={this.handleInputChange}
                                placeholder="Password"
                                type="password"
                                defaultValue=""
                                name="password"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </>
    );
  }
}
export default ProgilePage;
