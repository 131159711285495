import React from "react";

const Footer = () => (
	<footer className="site-footer">
		<div className="site-footer-legal">
			© 2019
			<a href="http://oncoustics.com"> OnCoustics</a>
		</div>
		<div className="site-footer-right" />
	</footer>
);

export default Footer;
