//******************* Devices ********************\\
export const FETCHING_DEVICES = "FETCHING_DEVICES";
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS";
export const GET_DEVICES_ERROR = "GET_DEVICES_ERROR";

export const FETCHING_DEVICE = "FETCHING_DEVICE";
export const GET_DEVICE_SUCCESS = "GET_DEVICE_SUCCESS";
export const GET_DEVICE_ERROR = "GET_DEVICE_ERROR";

export const FETCHING_DEVICE_LATEST_CASES_INFO =
  "FETCHING_DEVICE_LATEST_CASES_INFO";
export const GET_DEVICE_LATEST_CASES_INFO_SUCCESS =
  "GET_DEVICE_LATEST_CASES_INFO_SUCCESS";
export const GET_DEVICE_LATEST_CASES_INFO_ERROR =
  "GET_DEVICE_LATEST_CASES_INFO_ERROR";

export const FETCHING_DEVICE_CASES = "FETCHING_DEVICE_CASES";
export const GET_DEVICE_CASES_SUCCESS = "GET_DEVICE_CASES_SUCCESS";
export const GET_DEVICE_CASES_ERROR = "GET_DEVICE_LATEST_CASES_INFO_ERROR";

//******************* NOtifications ********************\\
export const SENDING_NOTIFICATION = "SENDING_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_ERROR = "SEND_NOTIFICATION_ERROR";

//******************* CASES ********************\\
export const FETCHING_CASES = "FETCHING_CASES";
export const FETCHING_CASE = "FETCHING_CASE";
export const GET_CASES_SUCCESS = "GET_CASES_SUCCESS";
export const GET_CASES_ERROR = "GET_CASES_ERROR";
export const GET_CASE_SUCCESS = "GET_CASE_SUCCESS";
export const GET_CASE_ERROR = "GET_CASE_ERROR";

//******************* Clarius CASES ********************\\
export const FETCHING_CLARIUS_CASES = "FETCHING_CLARIUS_CASES";
export const FETCHING_CLARIUS_CASE = "FETCHING_CLARIUS_CASE";
export const GET_CLARIUS_CASES_SUCCESS = "GET_CLARIUS_CASES_SUCCESS";
export const GET_CLARIUS_CASES_ERROR = "GET_CLARIUS_CASES_ERROR";
export const GET_CLARIUS_CASE_SUCCESS = "GET_CLARIUS_CASE_SUCCESS";
export const GET_CLARIUS_CASE_ERROR = "GET_CLARIUS_CASE_ERROR";

//******************* COMBINED CASES ********************\\
export const FETCHING_COMBINED_CASES = "FETCHING_COMBINED_CASES";
export const GET_COMBINED_CASES_SUCCESS = "GET_COMBINED_CASES_SUCCESS";
export const GET_COMBINED_CASES_ERROR = "GET_COMBINED_CASES_ERROR";
