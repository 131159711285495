import React from "react";
import store2 from "store2";
import { Link } from "react-router-dom";
const logout = () => {
  store2.remove("token");
  store2.remove("type");
  window.location.reload();
};

const Header = () => (
  <nav
    className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega"
    role="navigation"
  >
    <div className="navbar-header">
      <button
        type="button"
        className="navbar-toggler hamburger hamburger-close navbar-toggler-left hided"
        data-toggle="menubar"
      >
        <span className="sr-only">Toggle navigation</span>
        <span className="hamburger-bar" />
      </button>
      <button
        type="button"
        className="navbar-toggler collapsed"
        data-target="#site-navbar-collapse"
        data-toggle="collapse"
      >
        <i className="icon md-more" aria-hidden="true" />
      </button>
      <a href="/">
        <div
          className="navbar-brand navbar-brand-center site-gridmenu-toggle"
          data-toggle="gridmenu"
        >
          <img className="navbar-brand-logo" src="" alt="" title="" />
          <span className="navbar-brand-text hidden-xs-down">Oncoustics</span>
        </div>
      </a>
      <button
        type="button"
        className="navbar-toggler collapsed"
        data-target="#site-navbar-search"
        data-toggle="collapse"
      >
        <span className="sr-only">Toggle Search</span>
        <i className="icon md-search" aria-hidden="true" />
      </button>
    </div>
    <div className="navbar-container container-fluid">
      <div
        className="collapse navbar-collapse navbar-collapse-toolbar"
        id="site-navbar-collapse"
      >
        <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
          <li className="nav-item dropdown">
            <Link
              className="nav-link navbar-avatar"
              data-toggle="dropdown"
              to="#"
              onClick={logout}
              aria-expanded="false"
              data-animation="scale-up"
              role="button"
            >
              Logout
            </Link>
          </li>
        </ul>
      </div>
      <div className="collapse navbar-search-overlap" id="site-navbar-search">
        <form role="search">
          <div className="form-group">
            <div className="input-search">
              <i className="input-search-icon md-search" aria-hidden="true" />
              <input
                type="text"
                className="form-control"
                name="site-search"
                placeholder="Search..."
              />
              <button
                type="button"
                className="input-search-close icon md-close"
                data-target="#site-navbar-search"
                data-toggle="collapse"
                aria-label="Close"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </nav>
);

export default Header;
