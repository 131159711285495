import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../Store/Apis/BaseUrl";
import XLSX from "xlsx";
import { DatePicker, Spin, Collapse } from "antd";

import moment from "moment";
// Components
import BaseComponent from "../BaseComponent";
import DatatTableComponent from "../Partials/_DatatableComponet";

// Actions
import * as deviceActions from "../../Store/Actions/deviceActions";

// Helpers
import { dateFormat, checkDate } from "../../Helpers/dateHelper";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

function mapStateToProps(store) {
  return {
    devicesStore: store.devices
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deviceActions: bindActionCreators(deviceActions, dispatch)
  };
}

const datatableComponent = [
  {
    label: "Case ID",
    field: "caseId",
    sort: "asc",
    width: 150
  },
  {
    label: "date ",
    field: "date",
    sort: "asc",
    width: 150
  },
  {
    label: "Files Count",
    field: "filesCount",
    sort: "asc",
    width: 150
  },
  {
    label: "Clarius  Count",
    field: "clariusCount",
    sort: "asc",
    width: 150
  },
  {
    label: "S3 Count",
    field: "s3Count",
    sort: "asc",
    width: 150
  },
  {
    label: "country",
    field: "country",
    sort: "asc",
    width: 150
  },
  {
    label: "age",
    field: "age",
    sort: "asc",
    width: 150
  },
  {
    label: "gender",
    field: "gender",
    sort: "asc",
    width: 150
  },
  {
    label: "height",
    field: "height",
    sort: "asc",
    width: 150
  },
  {
    label: "weight",
    field: "weight",
    sort: "asc",
    width: 150
  },
  {
    label: "bmi",
    field: "bmi",
    sort: "asc",
    width: 150
  },
  {
    label: "liver diseases",
    field: "liver_diseases",
    sort: "asc",
    width: 150
  },

  {
    label: "lesion size",
    field: "lesion_size",
    sort: "asc",
    width: 150
  },
  {
    label: "number of lesions",
    field: "number_of_lesions",
    sort: "asc",
    width: 150
  },
  {
    label: "location of segment",
    field: "location_of_segment",
    sort: "asc",
    width: 150
  },
  {
    label: "presence ascites",
    field: "presence_ascites",
    sort: "asc",
    width: 150
  },
  {
    label: "portal vein thrombosis",
    field: "portal_vein_thrombosis",
    sort: "asc",
    width: 150
  },
  {
    label: "report comments",
    field: "report_comments",
    sort: "asc",
    width: 150
  },
  {
    label: "stage note",
    field: "stage_note",
    sort: "asc",
    width: 150
  },
  {
    label: "exams",
    field: "exams",
    sort: "asc",
    width: 150
  },
  {
    label: "shear wave elastography",
    field: "shear_wave_elastography",
    sort: "asc",
    width: 150
  },
  {
    label: "liver disease none reason",
    field: "liver_disease_none_reason",
    sort: "asc",
    width: 150
  },
  {
    label: "fibrosis fibroscan cap",
    field: "fibrosis_fibroscan_cap",
    sort: "asc",
    width: 150
  },
  {
    label: "fibrosis fibroscan kpa",
    field: "fibrosis_fibroscan_kpa",
    sort: "asc",
    width: 150
  },
  {
    label: "fatty grade other",
    field: "fatty_grade_other",
    sort: "asc",
    width: 150
  },
  {
    label: "cirrhosis grade",
    field: "cirrhosis_grade",
    sort: "asc",
    width: 150
  },
  {
    label: "history",
    field: "history",
    sort: "asc",
    width: 150
  },
  {
    label: "history other",
    field: "history_other",
    sort: "asc",
    width: 150
  },
  {
    label: "obesity note",
    field: "obesity_note",
    sort: "asc",
    width: 150
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 150
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 150
  }
];

const DatatableSyncActions = props => (
  <>
    <Link to={`/cases/${props.caseId}`}>
      <MDBBtn color="purple" size="sm">
        {`View `}
      </MDBBtn>
    </Link>
    <MDBBtn color="red" size="sm" onClick={() => props.syncCase(props.caseId)}>
      {`Sync `}
    </MDBBtn>
  </>
);

const DatatableViewActions = props => (
  <Link to={`/cases/${props.caseId}`}>
    <MDBBtn color="purple" size="sm">
      {`View `}
    </MDBBtn>
  </Link>
);

const actionBtn = (singleCase, syncCase) => {
  if (singleCase.s3Files.length > singleCase.clariusFiles) {
    return <DatatableViewActions caseId={singleCase.caseId} />;
  }
  if (singleCase.status === "syncing_videos") {
    return (
      <>
        <Link to={`/cases/${singleCase.caseId}`}>
          <MDBBtn color="purple" size="sm">
            {`View `}
          </MDBBtn>
        </Link>
        <MDBBtn color="green" size="sm">
          {`Syncing Videos.. `}
        </MDBBtn>
      </>
    );
  }
  if (singleCase.status === "syncing_tars") {
    return (
      <>
        <Link to={`/cases/${singleCase.caseId}`}>
          <MDBBtn color="purple" size="sm">
            {`View `}
          </MDBBtn>
        </Link>
        <MDBBtn color="green" size="sm">
          {`Syncing tars.. `}
        </MDBBtn>
      </>
    );
  }
  if (singleCase.fileNames.length !== singleCase.s3Files.length) {
    return (
      <DatatableSyncActions syncCase={syncCase} caseId={singleCase.caseId} />
    );
  }
  return <DatatableViewActions caseId={singleCase.caseId} />;
};

const statusField = singleCase => {
  if (singleCase.status === "syncing_tars") {
    return "syncing tars";
  }
  if (singleCase.status === "syncing_videos") {
    return "syncing videos";
  }
  if (singleCase.fileNames.length !== singleCase.s3Files.length) {
    return "sync";
  }
  return ".";
};

class DeviceCasesComponent extends React.Component {
  state = {
    cases: []
  };
  age;
  country;
  firstDate;
  secondDate;

  componentDidMount() {
    const { deviceId } = this.props.match.params;
    this.props.deviceActions.getDeviceCases(deviceId);
  }
  componentWillReceiveProps(props) {
    console.log("Prps", props);
    this.setState({ cases: props.devicesStore.device_cases });
  }

  exportToExcel = async () => {
    // const cases = []
    const cases = this.state.cases.map(c => {
      const caseInfo = {
        ...c
      };
      let key;
      for (key of Object.keys(c.patientInfo)) {
        caseInfo[key] = c.patientInfo[key] + "";
      }
      return caseInfo;
    });

    var ws = XLSX.utils.json_to_sheet(cases, {
      header: datatableComponent.map(r => r.field)
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    await XLSX.writeFile(wb, "Device Cases.xls");
  };

  syncCase = caseId => {
    axios
      .get(`${baseUrl}/case/${caseId}/sync`)
      .then(res => {
        // window.location.reload();
      })
      .catch(err => {
        return err;
      });
  };

  filterByDate = dates => {
    if (dates.length === 0) {
      this.firstDate = null;
      this.secondDate = null;
      return;
    }
    this.firstDate = dates[0];
    this.secondDate = dates[1];
  };

  /**
   * Filter by
   * age, country
   * dates
   * NEEDS REFACTOR
   */
  filter = () => {
    if (
      this.age.value.length > 0 &&
      this.country.value.length > 0 &&
      this.firstDate
    ) {
      console.log("Searching  All");

      const filtred_cases = this.props.devicesStore.device_cases.filter(
        singleCase => {
          if (
            singleCase.patientInfo.age === Number(this.age.value) &&
            singleCase.country === this.country.value &&
            checkDate(this.firstDate, this.secondDate, singleCase.createdAt)
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases
      });
      return;
    }
    if (this.age.value.length > 0 && this.country.value.length > 0) {
      console.log("Searching  Country and age");

      const filtred_cases = this.props.devicesStore.device_cases.filter(
        singleCase => {
          if (
            singleCase.patientInfo.age === Number(this.age.value) &&
            singleCase.country === this.country.value
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases
      });
      return;
    }

    if (this.age.value.length > 0 && this.firstDate) {
      console.log("Searching  age and Date");

      const filtred_cases = this.props.devicesStore.device_cases.filter(
        singleCase => {
          if (
            singleCase.patientInfo.age === Number(this.age.value) &&
            checkDate(this.firstDate, this.secondDate, singleCase.createdAt)
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases
      });
      return;
    }

    if (this.country.value.length > 0 && this.firstDate) {
      console.log("Searching  Country and Date");

      const filtred_cases = this.props.devicesStore.device_cases.filter(
        singleCase => {
          if (
            singleCase.country === this.country.value &&
            checkDate(this.firstDate, this.secondDate, singleCase.createdAt)
          ) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases
      });
      return;
    }

    if (this.country.value.length > 0) {
      console.log("Searching Countr Only");

      const filtred_cases = this.props.devicesStore.device_cases.filter(
        singleCase => {
          if (singleCase.country === this.country.value) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases
      });
      return;
    }

    if (this.age.value.length > 0) {
      console.log("Searching Age Only");
      const filtred_cases = this.props.devicesStore.device_cases.filter(
        singleCase => {
          if (singleCase.patientInfo.age === Number(this.age.value)) {
            return true;
          }
          return false;
        }
      );
      this.setState({
        cases: filtred_cases
      });
      return;
    }
  };

  sort = input => {
    const sortType = input.target.value;
    if (sortType === "sort") {
      this.setState({
        cases: this.props.devicesStore.device_cases
      });
      return;
    }
    const filtred_cases = this.props.devicesStore.device_cases.sort((a, b) => {
      let value = new Date(moment(b.createdAt)) - new Date(moment(a.createdAt));
      if (sortType === "date-dsc") {
        return value;
      }
      return value * -1;
    });

    this.setState({
      cases: filtred_cases
    });
  };

  clear = () => {
    this.age.value = "";
    this.country.value = "";
    this.setState({
      cases: this.props.devicesStore.device_cases
    });
  };

  render() {
    // const { deviceId } = this.props.match.params;
    let deviecName;
    if (this.state.cases.length > 0) {
      deviecName = this.state.cases[0].deviceName;
    }
    const rows = this.state.cases.map(singleCase => {
      return {
        caseId: singleCase.caseId,
        date: dateFormat(singleCase.createdAt),
        filesCount: singleCase.fileNames.length,
        clariusCount: singleCase.clariusFiles,
        s3Count: singleCase.s3Files.length,
        country: singleCase.country,
        age: singleCase.patientInfo.age || "",
        gender: singleCase.patientInfo.gender || "",
        height: singleCase.patientInfo.height || "",
        weight: singleCase.patientInfo.weight || "",
        bmi: singleCase.patientInfo.bmi || "",
        liver_diseases: singleCase.patientInfo.liver_diseases
          ? singleCase.patientInfo.liver_diseases + ""
          : "",
        lesion_size: singleCase.patientInfo.lesion_size || "",
        number_of_lesions: singleCase.patientInfo.number_of_lesions || "",
        location_of_segment: singleCase.patientInfo.location_of_segment || "",
        presence_ascites: singleCase.patientInfo.presence_ascites || "",
        portal_vein_thrombosis:
          singleCase.patientInfo.portal_vein_thrombosis || "",
        report_comments: singleCase.patientInfo.report_comments || "",
        stage_note: singleCase.patientInfo.stage_note || "",
        exams: singleCase.patientInfo.exams
          ? singleCase.patientInfo.exams + ""
          : "",
        shear_wave_elastography: singleCase.patientInfo.shear_wave_elastography,
        liver_disease_none_reason:
          singleCase.patientInfo.liver_disease_none_reason || "",
        fibrosis_fibroscan_cap:
          singleCase.patientInfo.fibrosis_fibroscan_cap || "",
        fibrosis_fibroscan_kpa:
          singleCase.patientInfo.fibrosis_fibroscan_kpa || "",
        fatty_grade_other: singleCase.patientInfo.fatty_grade_other || "",
        cirrhosis_grade: singleCase.patientInfo.cirrhosis_grade || "",
        history: singleCase.patientInfo.history
          ? singleCase.patientInfo.history + ""
          : "",
        history_other: singleCase.patientInfo.history_other || "",
        obesity_note: singleCase.patientInfo.obesity_note || "",
        actions: actionBtn(singleCase, this.syncCase),
        status: statusField(singleCase)
      };
    });
    return (
      <>
        <BaseComponent>
          <header className="panel-heading">
            <h3 className="panel-title"> {deviecName} Cases</h3>
          </header>
          <div className="panel-body">
            <div class="row">
              <Collapse defaultActiveKey={["1"]}>
                <Panel header="Syncing Cases" key="1">
                  <div>
                    <ul>
                      <li>Case-iD</li>
                      <li>Case-iD</li>
                      <li>Case-iD</li>
                      <li>Case-iD</li>
                    </ul>
                  </div>
                </Panel>
              </Collapse>
            </div>
            <br></br>
            <br></br>
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-success export-btn"
                  onClick={this.exportToExcel}
                >
                  Export to Excel
                </button>
                <RangePicker allowClear onChange={this.filterByDate} />
              </div>

              <div className="col-md-12 filter-tab">
                <div className="row">
                  <div className="col-md-3">
                    <input
                      type="number"
                      ref={el => (this.age = el)}
                      placeholder="Filter by Age"
                      className="form-control "
                    />
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      ref={el => (this.country = el)}
                      placeholder="Filter by country"
                      className="form-control "
                    />
                  </div>
                  <div className="col-md-3">
                    <button
                      className="btn btn-primary clear"
                      onClick={this.filter}
                    >
                      Filter
                    </button>
                    <button className="btn btn-danger " onClick={this.clear}>
                      Clear
                    </button>
                  </div>
                  <div className="col-md-3">
                    <select onChange={this.sort}>
                      <option value="sort">sort By</option>
                      <option value="date-asc">Date (ASC)</option>
                      <option value="date-dsc">Date (DSC)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.props.devicesStore.loading ? (
            <Spin />
          ) : (
            <DatatTableComponent columns={datatableComponent} rows={rows} />
          )}
        </BaseComponent>
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceCasesComponent);

// export default DeviceCasesComponent;
