import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  devices: [],
  device: null,
  error_message: [],
  hasError: false,
  loading: false,
  sending_notification: false,
  device_cases: [],
  device_cases_loading: false,
  device_cases_hasError: false,
  latest_cases_info: [],
  last_cases_hasError: false,
  latest_cases_info_loading: false,
};

export function deviceReducer(state = initialState, action) {
  switch (action.type) {
    //******************* Devices ********************\\
    case actionTypes.FETCHING_DEVICES:
      return {
        ...state,
        device: null,
        loading: true,
        hasError: false,
        error_message: [],
      };

    case actionTypes.GET_DEVICES_ERROR:
      return {
        ...state,
        saved: false,
        devices: [],
      };

    case actionTypes.GET_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        empty: false,
        devices: action.devices,
      };

    //******************* Single Device ********************\\
    case actionTypes.FETCHING_DEVICE:
      return {
        ...state,
        loading: true,
        hasError: false,
        error_message: [],
      };

    case actionTypes.GET_DEVICE_ERROR:
      return {
        ...state,
        saved: false,
        hasError: true,
        error_message: action.err,
        device: null,
      };

    case actionTypes.GET_DEVICE_SUCCESS:
      return {
        ...state,
        saved: true,
        empty: false,
        device: action.device,
      };

    //******************* Device Latest Cases ********************\\
    case actionTypes.FETCHING_DEVICE_LATEST_CASES_INFO:
      return {
        ...state,
        latest_cases_info_loading: true,
        last_cases_hasError: false,
      };

    case actionTypes.GET_DEVICE_LATEST_CASES_INFO_ERROR:
      return {
        ...state,
        last_cases_hasError: true,
        latest_cases_info_loading: true,
      };

    case actionTypes.GET_DEVICE_LATEST_CASES_INFO_SUCCESS:
      return {
        ...state,
        latest_cases_info_loading: false,
        latest_cases_info: action.latestCases,
      };

    //******************* Device  Cases ********************\\
    case actionTypes.FETCHING_DEVICE_CASES:
      return {
        ...state,
        loading: true,
        device_cases: [],
        device_cases_hasError: false,
        device_cases_loading: true,
      };

    case actionTypes.GET_DEVICE_CASES_ERROR:
      return {
        ...state,
        loading: false,
        device_cases_hasError: true,
        device_cases_loading: false,
        device_cases: [],
      };

    case actionTypes.GET_DEVICE_CASES_SUCCESS:
      return {
        ...state,
        device_cases: action.latestCases,
        loading: false,
        device_cases_loading: true,
        device_cases_hasError: false,
      };

    //******************* Notifications ********************\\
    case actionTypes.SENDING_NOTIFICATION:
      return {
        ...state,
        sending_notification: true,
      };

    case actionTypes.SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sending_notification: false,
      };

    case actionTypes.SEND_NOTIFICATION_ERROR:
      return {
        ...state,
        sending_notification: false,
      };

    default:
      return { ...state };
  }
}
